import React, { useState, useEffect } from "react";
import UploadFileViewer from "./UploadFileViewer.js";
import UploadFileEditor from "./UploadFileEditor.js";
import { CloseSquareTwoTone } from "@ant-design/icons";
import * as CommonFunc from "../../common/CommonFunc.js";
import FoodingService from "../../service/FoodingService";

const config = require("../../config/config.js");

const UploadedFilePreviewArea = (props) => {
  const [isUplodFileViewerVisible, setIsUplodFileViewerVisible] = useState(false);
  const [isUplodFileEditorVisible, setIsUplodFileEditorVisible] = useState(false);
  const [viewerModalRerender, setViewerModalRerender] = useState(0);
  const [editorModalRerender, setEditorModalRerender] = useState(0);
  const [viewFileName, setViewFileName] = useState("");
  const [progressRate, setProgressRate] = useState(0);
  const [progressStatus, setProgressStatus] = useState(0);
  const [imageCacheRenderer, setImageCacheRenderer] = useState(0);

  useEffect(() => {
    setProgressRate(props.progressRate);
    setProgressStatus(props.progressStatus);
  }, [props.progressRate, props.progressStatus]);

  const deletePreviewImage = async (fileName) => {
    let jsonData = {
      idea_idx: props.ideaIdx,
      user_id: props.userId,
      fileName: fileName,
    };

    FoodingService.fileDelete(JSON.stringify(jsonData))
      .then((res) => {
        if (res.status === 200) {
          CommonFunc.openNotification("success", "삭제가 완료됐습니다.", "");
          // 업로드 완료 후 데이터 저장 처리 (props로 전달받은 부모 함수 onDeleteFile 호출)
          props.onDeleteCompleted(fileName, props.uploadedFiles);
        }
      })
      .catch((err) => {
        CommonFunc.openNotification("error", "삭제를 실패했습니다.", err?.response?.data);
        
      });
  };

  const showUploadFileViewer = (fileName) => {
    setIsUplodFileViewerVisible(true);
    setViewFileName(fileName);
    setViewerModalRerender(viewerModalRerender + 1);
  };

  // UploadFileViewer에서 파일 에디터 모드로 변경시 호출될 펑션
  const openUploadFileEditor = async (fileName) => {
    setIsUplodFileEditorVisible(true);
    setViewFileName(fileName.fileName);
    setEditorModalRerender(editorModalRerender + 1);
  };

  // UploadFileEditor 모달 창 닫기시 호출될 펑션
  const closedUploadFileEditor = async (editUploadedFiles, isUpdated) => {
    props.onEditCompleted(editUploadedFiles);

    if (isUpdated) setImageCacheRenderer(imageCacheRenderer + 1);

    setIsUplodFileEditorVisible(false);
    // 신규이미지가 추가된 경우 viewer 창 다시 렌더링
    if (props.uploadedFiles.length !== editUploadedFiles.length)
      setViewerModalRerender(viewerModalRerender + 1);
  };

  return (
    <>
      <br />
      <div>
        {(() => {
          return props.uploadedFiles.map((fileName, index) => (
            <div key={fileName} className="previewarea-items" style={{ marginTop : 4}}>
              <img
                src={config.serverUrl +
                  "/api/getUploadedFile/" +
                  props.ideaIdx +
                  "/" +
                  props.writerUserId +
                  "/" +
                  fileName + "?isThumbnail=true" +
                  "&iamgeType=" + props.imageType + "&" +
                  imageCacheRenderer
                }
                onClick={() => showUploadFileViewer(fileName)}
                alt={fileName}
              />
              {(props.userId === props.writerUserId || props.isAdmin)&&
                <CloseSquareTwoTone
                  twoToneColor="red"
                  onClick={() => {
                    deletePreviewImage(fileName);
                  }}
                />
              }
            </div>
          ));
        })()}
        <div
          key="progress"
          className={`progress ${progressStatus === 0 ? "" : "progress-ing"}`}
        >
          <img src="/img/imgicon.png" alt="대체 이미지"/>
          <div
            style={{
              width: `${progressRate}%`,
            }}
          ></div>
        </div>
      </div>
      <div style={{ clear: "both" }}></div>
      <UploadFileViewer
        key={`viewer${viewerModalRerender}`}
        rerenderKey={imageCacheRenderer}
        userId={props.userId}
        writerUserId={props.writerUserId}
        ideaIdx={props.ideaIdx}
        isAdmin={props.isAdmin}
        fileName={viewFileName}
        uploadedFiles={props.uploadedFiles}
        isUplodFileViewerVisible={isUplodFileViewerVisible}
        openUploadFileEditor={openUploadFileEditor}
      />
      <UploadFileEditor
        key={`editor${editorModalRerender}`}
        ideaIdx={props.ideaIdx}
        writerUserId={props.writerUserId}
        fileName={viewFileName}
        uploadedFiles={props.uploadedFiles}
        isUplodFileEditorVisible={isUplodFileEditorVisible}
        closedUploadFileEditor={closedUploadFileEditor}
        showUploadFileViewer={showUploadFileViewer}
      />
    </>
  );
};

export default UploadedFilePreviewArea;
