import React, { Component } from "react";
import "antd/dist/antd.compact.css";
import FoodingService from "../../service/FoodingService";
import * as CommonFunc from "../components/functions/CommonFunc.js";
import { Typography, Space, Row, Col, Tag, Dropdown, Menu  } from "antd";
import { DownOutlined, WarningTwoTone } from "@ant-design/icons";
import moment from "moment";
const config = require("../../config/config.js");

export default class SelectedSpaceSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editableSpaceList: null,
      selectedSpaceInfo: null,
      currentSelectedSpaceTitle: "전체 스페이스",
      selectedMenuKey: "allSpace"
    };
  }

  componentDidMount() {
    if (!this.props.spaceIdx) {
      alert("잘못된 경로로 접근하셨습니다.");
      this.props.history.replace(`/fd/select/${this.props.userId}`);
    } else {
      this.getSpaceListByIdx();
    }
  }

  // 스페이스 idx를 통해 해당 스페이스 정보를 가져온다. 이후에 그걸로 필터 조회해서 리스트 업데이트.
  getSpaceListByIdx() {
    let isSpaceIdxList = this.props.spaceIdx && this.props.spaceIdx.split(",").length > 1; // 넘어온 값이 리스트인지 확인 하는 변수
    // let jsonData = { space_idx: isSpaceIdxList ? [...this.props.spaceIdx.split(",")].map(idx => parseInt(idx)) : this.props.spaceIdx };
    let jsonData = { space_idx: this.props.editableSpaceList};
    FoodingService.getSpaceList(JSON.stringify(jsonData))
    .then((res) => {
      if (res.status === 200) {
        let spaceInfoList = res.data;
        let spaceInfo = spaceInfoList.filter(space => parseInt(this.props.spaceIdx) === space.space_idx);
        // 스페이스 정보가 없거나 비공개인 경우, 아니면 특정대상인 경우면 adminUserList로 된 사람만 접근됨
        if (isSpaceIdxList && (!spaceInfo || spaceInfo.space_publish_type === 2 || (spaceInfo.space_publish_type === 1 && !config.adminUserList.includes(this.props.userId)))) {
          alert("잘못된 경로로 접근하셨습니다.");
          this.props.history.replace(`/fd/select/${this.props.userId}`);
          return;
        }

        this.setState({
            editableSpaceList: spaceInfoList,
            selectedSpaceInfo: isSpaceIdxList ? null : spaceInfo[0],
            currentSelectedSpaceTitle: isSpaceIdxList ? "전체 스페이스" : spaceInfo[0].space_title,
            selectedMenuKey: isSpaceIdxList ? "allSpace" : spaceInfo[0].space_idx
          },
          () => {
            this.props.applySpaceFilter({ space_idx: [isSpaceIdxList ? this.props.spaceIdx.split(",") : spaceInfo[0].space_idx] }, isSpaceIdxList ? spaceInfoList : spaceInfo[0]);
          }
        );
      } else CommonFunc.openNotification("error", "조회를 실패했습니다.", res.status);
    })
    .catch((err) => {
      console.dir(err);
      CommonFunc.openNotification("error", "조회를 실패했습니다.", err?.response?.data);
    });
  }

  selectSpace(spaceInfo, isAll) {
    this.props.applySpaceFilter({ space_idx: isAll ? this.state.editableSpaceList.map(space => space.space_idx) : [spaceInfo.space_idx] }, spaceInfo);
    
    this.setState({
      selectedMenuKey: isAll ? "allSpace" : spaceInfo.space_idx,
      selectedSpaceInfo: isAll ? null : spaceInfo,
      currentSelectedSpaceTitle: isAll ? "전체 스페이스" : spaceInfo.space_title
    });
  }

  render() {
    if (!this.state.editableSpaceList) return null;
    return (
      <Row gutter={[0, 16]}>
        <Dropdown overlay={(
            <Menu selectedKeys={[String(this.state.selectedMenuKey)]}>
              <Menu.Item key="allSpace" style={{ width: "100%" }} onClick={() => this.selectSpace(null ,true)}>전체 스페이스</Menu.Item>
              {this.state.editableSpaceList.map(space => (
                <Menu.Item key={space.space_idx} style={{ width: "100%" }} onClick={() => this.selectSpace(space)}>{space.space_title}</Menu.Item>
              ))}
            </Menu>
        )}>
          <a onClick={e => e.preventDefault()}>
            <Space style={{ fontSize: 16 }}>
             {this.state.currentSelectedSpaceTitle}<DownOutlined/>
            </Space>
          </a>
        </Dropdown>
          {this.state.selectedSpaceInfo &&
          <>
            {/* <Typography.Title level={4}>{this.state.selectedSpaceInfo?.space_title}</Typography.Title> */}
            {/* 상태가 Close이거나 기간설정이 되었는데, 기간이 지난 경우 */}
            {(this.state.selectedSpaceInfo?.space_state === 1 || (this.state.selectedSpaceInfo?.space_date_type === 1 && moment().diff(moment(this.state.selectedSpaceInfo?.space_end_date)) > 0)) && (
              <Col span={24}>
                <Space size="large">
                  <Tag icon={<WarningTwoTone twoToneColor="#F3C62D" />} color="#808080" style={{ padding: 12 }}>
                      종료된 스페이스 입니다.
                  </Tag>
                </Space>
              </Col>
            )}
            <Col span={24}>
              <Typography.Text>
                {this.state.selectedSpaceInfo?.space_description?.split("\n").map((line, index) => {
                  return (
                    <span key={`line${index}`}>
                      {line}
                      <br />
                    </span>
                  );
                })}
              </Typography.Text>
            </Col>
          </>
        }
      </Row>
    );
  }
}
