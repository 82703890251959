import React, { Component, createRef } from "react";
import { Spin, Button, Row, Col, Input, Typography, Divider } from "antd";
import "antd/dist/antd.compact.css";
import FoodingService from "../service/FoodingService";
import ResultList from "./components/ResultList";
import { PlusOutlined } from '@ant-design/icons';
const { Title } = Typography;

export default class FoodingResultList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasMore: true, // 더 가져올 데이터 여부
      loading: false,
      userId: this.props.userId || this.props.match?.params?.userId,
      resultList: [],
      resultTagList: [],
      searchText: "",
      searchTags: [],
    };
    this.loaderRef = createRef(); // 로딩 트리거용 Ref
    this.observer = null; // Intersection Observer 객체
  }

  componentDidMount() {
    // 쿼리파라미터에서 검색태그명을 추출
    const params = new URLSearchParams(window.location.search);

    if (params.get('searchTag')) {
      this.setState({
        searchTags: [params.get('searchTag')]
      });
    }
    this.observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && this.state.hasMore) {
          this.loadMoreData();
        }
      },
      { threshold: 0.6 } // 요소가 완전히 보일 때 트리거
    );

    if (this.loaderRef.current) {
      this.observer.observe(this.loaderRef.current);
    }

    // 초기 데이터 로드
    this.loadMoreData();
    this.findResultTagList();
  }

  componentWillUnmount() {
    if (this.observer && this.loaderRef.current) {
      this.observer.unobserve(this.loaderRef.current);
    }
  }

  // 데이터 로드 함수
  loadMoreData = () => {
    if (this.state.loading) return;
    this.setState({ loading: true });

    setTimeout(async() => {
      const newPage = Math.floor(this.state.resultList.length / 5) + 1;
      const newItems = await this.findResultList(newPage);
      this.setState((prevState) => ({
        resultList: [...prevState.resultList, ...newItems],
        loading: false,
        hasMore: newItems.length < 5 ? false : true,
      }));
    }, 500);
  }

  findResultTagList = async () => {
    let response = await FoodingService.getResultTagList();
    let responseData = response.data || [];
    this.setState({
      resultTagList: responseData
    });
  }

  findResultList = async (page) => {
    const { searchText, searchTags, userId } = this.state;
    let listFilterParams = {
      searchText,
      searchTags,
      isAdmin: this.props.isAdminAuth ? true : false,
      page_current: page,
      page_size: 5,
      user_id: userId
    };
    let response = await FoodingService.getResultList(JSON.stringify(listFilterParams));
    let responseData = response.data || [];
    
    responseData = responseData.map(response => {
      var contents = response.result_contents;
      var images = JSON.parse(response.result_images);
      // images.map(image => contents = contents.replaceAll("#" + image.name + "#" , ""));
      return {...response, result_images: images, result_contents: contents}; 
    });
    
    return responseData;
  }

  clickResultRow = (resultIdx) => {                              
    window.open(`/fd/result/detail/${resultIdx}`, "_blank");
  }

  searchByKeyword = () => {
    this.setState({
      resultList: [],
      searchText: this.state.searchText,
      loading: true
    }, () => {
      setTimeout(async() => {
        this.findResultList(1);

        const newItems = await this.findResultList(1);
        this.setState((prevState) => ({
          resultList: newItems,
          loading: false,
          hasMore: newItems.length < 5 ? false : true,
        }));
      }, 500);
    });
  }

  clearFilter = (e) => {
    e.preventDefault();
    this.setState({
      searchTags: [],
      resultList: []
    }, () => {
      setTimeout(async() => {
        this.findResultList(1);

        const newItems = await this.findResultList(1);
        this.setState((prevState) => ({
          resultList: newItems,
          loading: false,
          hasMore: newItems.length < 5 ? false : true,
        }));
      }, 500);
    });
  }

  render() {
    const { resultList, loading, resultTagList, searchTags } = this.state;

    return (
      <div style={{ backgroundColor: "#fff", margin: "10px" }}>
        <Row gutter={[10, 10]}>
          <Col span={24} style={{ display: "flex", alignItems: "center", marginLeft: "25px", marginTop: "6px"}}>
           <Title level={1} style={{ marginTop: "0.5em" }}>UX Labtory</Title>
           <p style={{ color: "rgba(0,0,0,0.45)", marginLeft: "10px", marginTop: "2.5em"}}>UX Lab Story</p>
          </Col>
          {this.props.isAdminAuth && 
            <Col span={24}>
              <Button
                type="primary"
                shape="round"
                icon={<PlusOutlined/>}
                onClick={(e) => {
                  e.preventDefault();
                  // const queryParams = new URLSearchParams({
                  //   userId: this.state.userId,
                  // }).toString();

                  window.open(`/fd/edit/result`, "_blank");
                }}
                size="middle"
                style={{
                  marginLeft: "22px",
                }}
                className="btn-new"
              >
                작성하기
              </Button>
            </Col>
          }
          <Divider style={{ margin: "0px"}}/>
          <Col span={18}>
             <ResultList resultList={resultList} clickResultRow={this.clickResultRow}/>
              {loading && (
                  <div style={{ textAlign: "center", padding: "10px" }}>
                    <Spin />
                  </div>
              )}
             <div ref={this.loaderRef} style={{ height: "20px", background: "transparent" }}></div>
          </Col>
          <Col span={6}>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <h2>필터</h2>
                  <a
                    style={{ marginBottom: "0.5em", marginRight: "20px" }}
                    onClick={this.clearFilter}>초기화</a>
              </div>
                <Input.Search
                  style={{width: "100%", left: 0, marginBottom: "15px", paddingRight: "8px" }}
                  value={this.state.searchText}
                  placeholder="제목 및 내용 검색"
                  onSearch={() => this.searchByKeyword()}
                  onPressEnter={() => this.searchByKeyword()}
                  onChange={(e) => this.setState({ searchText: e.target.value })}
                  allowClear
                />
              {resultTagList.map((tag, index) => (
                <Button
                  key={"resultTagKey"+index}
                  ghost={searchTags.filter(searchTag => searchTag === tag).length === 0}
                  type="primary"
                  shape="round"
                  onClick={(e) => {
                    e.preventDefault();
                    let filteredSearchTags = searchTags.filter(searchTag => searchTag === tag).length > 0 ?  searchTags.filter(searchTag => searchTag !== tag) : [...searchTags, tag];

                    this.setState({
                      resultList: [],
                      searchTags: filteredSearchTags,
                      loading: true
                    }, () => {
                      setTimeout(async() => {
                        this.findResultList(1);

                        const newItems = await this.findResultList(1);
                        this.setState((prevState) => ({
                          resultList: newItems,
                          loading: false,
                          hasMore: newItems.length < 5 ? false : true,
                        }));
                      }, 500);
                    })
                  }}
                  size="middle"
                  style={{
                    marginRight: "4px",
                    marginBottom: "6px",
                  }}
                  className="btn-new"
                >
                #{tag}
                </Button>
              ))}
          </Col>
        </Row>
      </div>
    );
  }
}
