import React, { useState, useRef, useEffect } from "react";
import { Modal, Progress, Row, Col } from "antd";
import "../../css/tui-color-picker.css";
import "../../css/tui-image-editor.css";
import "../../css/App.css";
import ImageEditor from "@toast-ui/react-image-editor";
import axios from "axios";
import * as CommonFunc from "../../common/CommonFunc.js";
const config = require("../../config/config.js");

// ImageEditor테마 설정 : 상단 로고 삭제, load 버튼 삭제
const myTheme = {
  "common.bi.image": "",
  "common.bisize.width": "0",
  "common.bisize.height": "0",
  "common.backgroundImage": "none",
  "common.border": "0px",

  // header
  "header.backgroundImage": "none",
  "header.backgroundColor": "transparent",
  "header.border": "0px",

  // load button
  "loadButton.backgroundColor": "#fff",
  "loadButton.border": "1px solid #ddd",
  "loadButton.color": "#222",
  "loadButton.fontFamily": "NotoSans, sans-serif",
  "loadButton.fontSize": "12px",
  "loadButton.display": "none",

  // download button
  "downloadButton.backgroundColor": "#fff",
  "downloadButton.border": "1px solid #ddd",
  "downloadButton.color": "#222",
  "downloadButton.fontFamily": "NotoSans, sans-serif",
  "downloadButton.fontSize": "12px",
  "downloadButton.display": "none",
};

const UploadFileEditor = (props) => {
  const [fileName, setFileName] = useState(props.fileName);
  const [isUplodFileEditorVisible, setIsUploadFileEditorVisible] = useState(props.isUplodFileEditorVisible);
  let [uploadedFiles, setUploadedFiles] = useState(props.uploadedFiles);
  const [ideaIdx, setIdeaIdx] = useState(props.ideaIdx);
  const editorRef = useRef(null);
  const [progressRate, setProgressRate] = useState(0);
  const [progressStatus, setProgressStatus] = useState(0);
  useEffect(() => {
    if (editorRef.current) {
      const editor = editorRef.current.imageEditorInst;
      if (editor) {
        // Shape Stroke 의 기본 색상을 빨간색으로 변경
        editor.ui.shape.options.stroke = "#ff0000";
        editor.ui.shape._els.strokeColorpicker._color = "#ff0000";
        editor.ui.shape.colorPickerControls[1].colorElement.style.backgroundColor =
          "#ff0000";
        // Text Color 의 기본 색상을 빨간색으로 변경
        editor.ui.text.colorPickerInputBox.defaultValue = "#ff0000";
        editor.ui.text._els.color = "#ff0000";
        editor.ui.text._els.textColorpicker.color = "#ff0000";
        editor.ui.text._els.textColorpicker.colorElement.style.backgroundColor =
          "#ff0000";

        // 이미지 편집 저장을 위한 저장 버튼 생성
        var element = editorRef.current.getRootElement();
        var applyButton = document.createElement("button");
        applyButton.innerHTML = "저장";
        applyButton.style.backgroundColor = "#1890ff";
        applyButton.style.color = "#fff";
        applyButton.style.fontFamily = "NotoSans, sans-serif";
        applyButton.style.fontSize = "12px";
        applyButton.style.border = "1px solid #000";
        applyButton.style.width = "80px";

        // 버튼을 맨 끝에 추가
        var headerButtons = element.querySelector(
          ".tui-image-editor-header-buttons"
        );

        headerButtons.appendChild(applyButton);
        
        var deleteBtn = element.querySelector(".tie-btn-delete")
        deleteBtn.style.display = "none";
        var deleteAllBtn = element.querySelector(".tie-btn-deleteAll")
        deleteAllBtn.style.display = "none";

        // 버튼 클릭 이벤트 핸들러 추가
        applyButton.addEventListener("click", () => {
          handleApply();
        });
      }
    }
  }, []);

  useEffect(() => {
    setFileName(props.fileName)
    setUploadedFiles(props.uploadedFiles)
  }, [props.fileName, props.uploadedFiles]);
  
  const handleApply = () => {
    saveImage(fileName , "1"); // 파일 덮어쓰기
    const editorInstance = editorRef.current.getInstance();
    editorInstance.clearUndoStack();
    editorInstance.clearRedoStack();
  };

  const closeModal = () => {
    const editorInstance = editorRef.current.getInstance();
    if (!editorInstance.isEmptyUndoStack()) {
      if (
        !window.confirm("이미지의 변경 사항이 있습니다. 무시하고 창을 닫으시겠습니까?")
      ) {
        return;
      }
      editorInstance.clearUndoStack();
      editorInstance.clearRedoStack();
      setIsUploadFileEditorVisible(false);
      props.closedUploadFileEditor(uploadedFiles, true); // 부모창 이미지 목록 갱신 처리
    } else {
      editorInstance.clearUndoStack();
      editorInstance.clearRedoStack();
      setIsUploadFileEditorVisible(false);
      props.closedUploadFileEditor(uploadedFiles, false); // 부모창 이미지 목록 갱신 처리
    }
  };

  // ImageEditor 이미지 Blob 변환
  const dataURItoBlob = (dataURI, mimeString) => {
    var byteString = atob(dataURI.split(",")[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  };

  const saveImage = (filename, overwrite) => {
    const editorInstance = editorRef.current.getInstance();
    editorInstance.resetZoom(); // 저장하기 전에 이미지 zoom 100%로 변경
    const data = editorInstance.toDataURL();
    const mimeString = getFileMimeType(filename);
    var blob = dataURItoBlob(data, mimeString);
    const file = new File([blob], filename, {
      type: mimeString,
      lastModified: new Date(),
    });
    const formData = new FormData();
    formData.append("file", file);
    if (props.resultIdx) {
      formData.append("result_idx", props.resultIdx); // resultIdx 정보 추가
    } else {
      formData.append("idea_idx", ideaIdx); // ideaIdx 정보 추가
    }
    formData.append("user_id", props.writerUserId); // issueIdx 정보 추가
    formData.append("encodedFileName", encodeURIComponent(filename)); // 인코딩된 파일 이름 정보 추가
    if (overwrite !== undefined) {
      formData.append("overwrite", "1"); // 이미지 overwrite
    }
    /* for (let key of formData.keys()) {
      console.log(key, ":", formData.get(key));
    } */
    axios
      .post( config.serverUrl + "/api/fileUpload", formData, {
        onUploadProgress: (progressEvent) => {
          const percent = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setTimeout(() => {
            if (progressStatus === 0) setProgressStatus(1);
          }, 400); // 1000밀리초 = 1초

          setProgressRate(percent);
          if (percent === 100) {
            setTimeout(() => {
              setProgressStatus(0);
            }, 400); // 1000밀리초 = 1초
          }

          // 프로그레스 바 로그 출력
          //console.log(`업로드 진행률: ${percent}%`);
        },
      })
      .then((response) => {
        // 성공적으로 업로드되었을 때의 처리
        if (overwrite === undefined) {
          console.log("이미지를 새로 추가하고 이미지를 state에 추가한다.");
          setUploadedFiles((prevUploadedFiles) => [
            ...prevUploadedFiles,
            response.data.data.fileName,
          ]);
          CommonFunc.openNotification(
            "success",
            "파일 생성을 성공했습니다.",
            ""
          );
        } else {
          console.log("이미지만 갱신한다.");
          setUploadedFiles((prevUploadedFiles) => prevUploadedFiles);
          CommonFunc.openNotification(
            "success",
            "파일 업데이트를 성공했습니다.",
            ""
          );
        }
        
        setIsUploadFileEditorVisible(false);
        props.closedUploadFileEditor(uploadedFiles, true); // 부모창 이미지 목록 갱신 처리
      })
      .catch((e) => {
        // 업로드 중에 발생한 오류 처리
        CommonFunc.openNotification(
          "error",
          "파일 저장이 실패했습니다.",
          e.message
        );
        console.log({
          "1. ideaIdx": ideaIdx,
          "2. filename": filename,
          "3. formData": formData,
          "4. message": e.message,
        });
      });
  };

  const getFileMimeType = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    switch (extension) {
      case "jpg":
      case "jpeg":
        return "image/jpeg";
      case "png":
        return "image/png";
      case "gif":
        return "image/gif";
      case "webp":
        return "image/webp";
      case "webp2":
        return "image/webp";
      default:
        return "application/octet-stream";
    }
  };

  return (
    <Modal
      title="File Editor"
      visible={isUplodFileEditorVisible}
      cancelText="닫기"
      onCancel={closeModal}
      footer={null}
      width={1033}
    >
      <Row gutter={[4, 4]}>
        <Col span={24}>
          {progressStatus === 1 && (
            <Progress
              percent={progressRate}
              showInfo={false}
              status="active"
              type="line"
            />
          )}
        </Col>
        <Col span={24}>
          <ImageEditor
            ref={editorRef}
            includeUI={{
              loadImage: {
                path: config.serverUrl + "/api/getUploadedFile/" + (props.resultIdx ? props.resultIdx : props.ideaIdx) + "/" + props.writerUserId
                  + "/" + (fileName ? fileName : props.fileName) + "?" + Date.now() + "&isResult=" + (props.resultIdx ? "1" : "0"),
                name:  (fileName ? fileName : props.fileName).split(".")[0],
              },
              theme: myTheme,
              menu: ["draw", "shape", "text"],
              initMenu: "shape",
              uiSize: {
                width: "984px",
                height: "800px",
              },
              menuBarPosition: "bottom",
            }}
            cssMaxHeight={600}
            cssMaxWidth={1000}
            selectionStyle={{
              cornerSize: 20,
              rotatingPointOffset: 70,
            }}
            usageStatistics={false}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default UploadFileEditor;
