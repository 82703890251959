import axios from "axios";
const config = require("../config/config.js");

class FoodingService {
  // 의견 관련 service, 조회, 추가, 삭제 , 수정
  getIdeaList(data = "") {
    return axios.get(config.serverUrl + "/api/getIdeaList/" + data);
  }

  addNewIdea(data = "") {
    return axios({
      method: "post",
      url: config.serverUrl + "/api/addNewIdea/",
      params: data,
    });
  }

  updateIdea(data = "") {
    return axios({
      method: "post",
      url: config.serverUrl + "/api/updateIdea/",
      params: data,
    });
  }

  deleteIdea(data = "") {
    return axios.get(config.serverUrl + "/api/deleteIdea/" + data);
  }

  // 의견 카운트 조회
  getIdeaListCount(name = "") {
    return axios.get(config.serverUrl + "/api/getIdeaListCount/" + name);
  }

  // 링크 관련 service 조회, 추가, 수정, 삭제
  getLinkList(data = "") {
    return axios.get(config.serverUrl + "/api/getLinkList/" + data);
  }

  addNewLink(data = "") {
    return axios({
      method: "post",
      url: config.serverUrl + "/api/addNewLink/",
      params: data,
    });
  }

  updateLink(data = "") {
    return axios({
      method: "post",
      url: config.serverUrl + "/api/updateLink/",
      params: data,
    });
  }

  deleteLink(data = "") {
    return axios({
      method: "post",
      url: config.serverUrl + "/api/deleteLink/",
      params: data,
    });
  }

  // 코멘트 관련 service 조회, 추가, 수정, 삭제
  getCommentList(data = "") {
    return axios.get(config.serverUrl + "/api/getCommentList/" + data);
  }

  addNewComment(data = "") {
    return axios({
      method: "post",
      url: config.serverUrl + "/api/addNewComment/",
      params: data,
    });
  }
  updateComment(data = "") {
    console.log("@data", data)
    return axios({
      method: "post",
      url: config.serverUrl + "/api/updateComment/",
      params: data,
    });
  }

  deleteComment(data = "") {
    return axios.get(config.serverUrl + "/api/deleteComment/" + data);
  }

  // 좋아요 관련 service 조회, 추가, 삭제
  getLikeInfo(data = "") {
    return axios.get(config.serverUrl + "/api/getLikeInfo/" + data);
  }
  addNewLike(data = "") {
    return axios({
      method: "post",
      url: config.serverUrl + "/api/addNewLike/",
      params: data,
    });
  }

  deleteLike(data = "") {
    return axios.get(config.serverUrl + "/api/deleteLike/" + data);
  }

  // 스페이스 관련 service 조회, 수정, 추가
  getSpaceList(data = "") {
    return axios.get(config.serverUrl + "/api/getSpaceList/" + data);
  }
  getEditableSpaceList(data = "") {
    return axios.get(config.serverUrl + "/api/getEditableSpaceList/" + data);
  }
  addNewSpace(data = "") {
    return axios({
      method: "post",
      url: config.serverUrl + "/api/addNewSpace/",
      params: data,
    });
  }
  updateSpace(data = "") {
    return axios({
      method: "post",
      url: config.serverUrl + "/api/updateSpace/",
      params: data,
    });
  }

  modifyResult(data = {}, currentState) {
    return axios({
      method: "post", 
      url: config.serverUrl + "/api/" + (currentState === "insert" ? "addNewResult" : "updateResult"),
      data,
    });
  }
  
  deleteResult(data = "") {
    return axios.get(config.serverUrl + "/api/deleteResult/" + data);
  }

  // 의견 관련 service, 조회, 추가, 삭제 , 수정
  async getResultList(data = "") {
    return await axios.get(config.serverUrl + "/api/getResultList/" + data);
  }

  async getResultTagList() {
    return await axios.get(config.serverUrl + "/api/getResultTagList/");
  }

  
  deleteSpace(data = "") {
    return axios.get(config.serverUrl + "/api/deleteSpace/" + data);
  }


  async checkAuthUser(token="", isFirstLogin=false) {
    return await axios.get(config.serverUrl + `/api/checkAuthuser?isFirstLogin=${isFirstLogin}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  } 

  // 이메일 로그인 service
  async loginWithEmail(data = {}) {
    return await axios.post(config.serverUrl+"/api/requestAccess", data);
  }

  // 로그인 service
  login(data = "") {
    return axios({
      method: "post",
      url: config.serverUrl + "/api/login/",
      data,
    });
  }

  // 파일 삭제
  fileDelete(data = "") {
    return axios({
      method: "post",
      url: config.serverUrl + "/api/fileDelete/",
      params: data,
    });
  }

  // 파일 리스트 조회
  getFileList(data = "") {
    return axios({
      method: "post",
      url: config.serverUrl + "/api/getFileList/",
      params: data,
    });
  }

  sendMailForNewIdea(data = "") {
    return axios.get(config.serverUrl + "/api/sendMailForNewIdea/");
  }

  verifyEmailAuth(data = "") {
    return axios.get(config.serverUrl + "/api/verifyEmailAuth/" + data);
  }
}

export default new FoodingService();
