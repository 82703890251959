import React, { Component } from 'react';
import { Button, Divider, Row, Col, Card, Input, Space, Badge, BackTop, Typography, Spin} from 'antd';
import { LikeTwoTone, LikeOutlined, MessageOutlined, DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import FoodingService from "../service/FoodingService";
import * as CommonFunc from "./components/functions/CommonFunc.js";
import MarkdownPreview from "@uiw/react-markdown-preview";
import moment from "moment";
import "moment/locale/ko";
import "antd/dist/antd.compact.css";

const config = require("../config/config.js");
const { TextArea } = Input;
const { Title } = Typography;

export default class FoodingResultDetail extends Component {
  state = {
    userId: "",
    fileList: [],
    resultIdx: this.props.match?.params?.resultIdx,
    resultTitle: "",
    resultContents: "",
    resultCommentList: [],
    resultCreateDate: "",
    resultIssueStateIdx: 1,
    resultTags: [],
    viewCnt: 0,
    likeCnt: 0,
    likeInfo: null,
    inputComment: "",
    inputUpdateComment: "",
    loading: false
  };
  
  resultCommentRef = React.createRef();
  commentAreaRef = React.createRef();
  
  componentDidMount () {
    this.findResultDetail();
  
    const params = new URLSearchParams(window.location.search);

    this.setState({
      userId:params.get('userId')
    });
  }

  findResultDetail = async () => {
    this.setState({
      loading: true
    });

    let listFilterParams = {
        user_id: this.state.userId,
        isAdmin: this.props.isAdminAuth ? true : false,
        page_current: 1,
        page_size: 1,
        result_idx: this.props.match?.params?.resultIdx
    };
    let response = await FoodingService.getResultList(JSON.stringify(listFilterParams));
    let responseData = response.data[0];
    if (responseData) {
      console.log(responseData)
      let fileList = JSON.parse(responseData.result_images);
      this.setState({
        resultIdx: responseData.result_idx,
        resultTitle: responseData.result_title,
        resultContents: this.convertContents(responseData.result_contents, fileList),
        resultIssueStateIdx: responseData.result_issue_state_idx,
        resultCreateDate: responseData.create_date,
        resultTags: JSON.parse(responseData.result_tags),
        fileList,
        likeCnt: responseData.like_cnt,
        viewCnt: responseData.view_cnt,
        loading: false
      }, () => {
        this.getLikeInfo();
        this.getCommentList();
      });
    }
  }

  convertContents = (contents, fileList) => {
    let convertContent = contents;
    // 이미지 마크업을 실제 이미지로 변환
    fileList.forEach(file => {
      const imageMarkup = `!${file.name}!`;
      const imgPath = config.serverUrl + "/api/getUploadedFile/" + this.state.resultIdx + "/admin/" + file.name + "?isResult=1"
      if (convertContent.indexOf(imageMarkup) !== -1) {
        const imageHtml = `<img src="${imgPath}" alt="${file.name}" style="max-width: 100%; margin: 10px 0;" />`;
        convertContent = convertContent.split(imageMarkup).join(imageHtml);
      }
    });

    // 줄바꿈을 <br>로 변환
    // convertContent = convertContent.replace(/\n/g, '<br>');

    return convertContent;
  }

  getCommentList = () => {
    const jsonData = {
      result_idx: this.state.resultIdx,
      user_id: this.state.userId,
    };

    FoodingService.getCommentList(JSON.stringify(jsonData))
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            resultCommentList: res.data?.length > 0 ? res.data : [],
          });
        } else CommonFunc.openNotification("error", "조회를 실패했습니다.", res.status);
      })
      .catch((err) => {
        CommonFunc.openNotification("error", "조회를 실패했습니다.", err?.response?.data);
      });
  }

  updateComment = (comment_idx) => {
    if (!this.state.inputUpdateComment || this.state.inputUpdateComment.trim() === "") {
      CommonFunc.openNotification("error", "변경할 댓글을 입력해주세요.");
      return;
    }
    const jsonData = {
      comment_idx,
      comment: this.state.inputUpdateComment,
    };

    FoodingService.updateComment(JSON.stringify(jsonData))
      .then((res) => {
        if (res.status === 200) {
          // 리스트의 댓글 내용 수정
          let createDate = res.data?.rows[0].create_date;
          let comment = res.data?.rows[0].comment;

          this.updateCommentData(comment_idx, createDate, comment);
          CommonFunc.openNotification("success", "댓글이 수정되었습니다.");
        } else CommonFunc.openNotification("error", "댓글 수정을 실패했습니다.", res.status);
      })
      .catch((err) => {
        CommonFunc.openNotification("error", "댓글 수정을 실패했습니다.", err?.response?.data);
      });
  }

  updateCommentData = (comment_idx, create_date, comment) => {
    let updatedCommentList = JSON.parse(JSON.stringify(this.state.resultCommentList));
    let index = updatedCommentList.findIndex((comment) => parseInt(comment["comment_idx"]) === parseInt(comment_idx)); // dataList 에서 issue_idx 일치하는 행의 index 추출
    updatedCommentList[index] = { ...updatedCommentList[index], comment, create_date, isEditComment: false }; // index 행의 정보만 변경

    this.setState({
      resultCommentList: updatedCommentList,
    });
  }

  deleteComment = (comment_idx) => {
    let isDelete = window.confirm("댓글을 삭제하시겠습니까?");

    if (!isDelete) {
      return;
    }

    const jsonData = {
      result_idx: this.state.resultIdx,
      comment_idx,
    };

    FoodingService.deleteComment(JSON.stringify(jsonData))
      .then((res) => {
        if (res.status === 200) {
          CommonFunc.openNotification("success", "댓글을 삭제했습니다.", "");

          this.getCommentList();
        } else {
          // Error
          CommonFunc.openNotification("error", "댓글 삭제를 실패했습니다.", res.status);
        }
      })
      .catch((err) => {
        CommonFunc.openNotification("error", "댓글 삭제를 실패했습니다.", err?.response?.data);
      });
  }

  addNewComment = () => {
    if (!this.state.inputComment || this.state.inputComment.trim() === "") {
      CommonFunc.openNotification("error", "댓글을 입력해주세요.");
      return;
    }

    const jsonData = {
      result_idx: this.state.resultIdx,
      user_id: this.state.userId,
      comment: this.state.inputComment,
      isAdmin: this.props.isAdminAuth
    };

    FoodingService.addNewComment(JSON.stringify(jsonData))
    .then((res) => {
      if (res.status === 200) {
        this.setState({
          inputComment: "",
          isRefreshLinkList: true,
        });
        CommonFunc.openNotification("success", "댓글이 추가되었습니다.");

        this.getCommentList();
      } else CommonFunc.openNotification("error", "댓글 추가를 실패했습니다.", res.status);
    })
    .catch((err) => {
      CommonFunc.openNotification("error", "댓글 추가를 실패했습니다.", err?.response?.data);
    });
  }

  getLikeInfo = () => {
    const jsonData = {
      result_idx: this.state.resultIdx,
      user_id: this.props.isAdminAuth ? "admin" : this.state.userId,
    };
    FoodingService.getLikeInfo(JSON.stringify(jsonData))
    .then((res) => {
      if (res.status === 200) {
        this.setState({
          likeInfo: res.data[0],
        });
      } else CommonFunc.openNotification("error", "조회를 실패했습니다.", res.status);
    })
    .catch((err) => {
      CommonFunc.openNotification("error", "조회를 실패했습니다.", err?.response?.data);
    });
  }

  addNewLike = () => {
    const jsonData = {
      result_idx: this.state.resultIdx,
      user_id: this.state.userId,
    };

    FoodingService.addNewLike(JSON.stringify(jsonData))
    .then((res) => {
      if (res.status === 200) {
        let likeCnt = res.data.rows[0]?.like_cnt;
        CommonFunc.openNotification("success", "좋아요를 눌렀습니다.");

        // 좋아요 카운트랑 좋아요 정보 state로 초기화 - 좋아요 정보는 현재 좋아요 누른 사용자의 정보임 이걸로 나중에 삭제하는데 사용
        this.setState({
          likeCnt,
          likeInfo: res.data.likeInfo,
          isRefreshLinkList: true,
        });
      } else CommonFunc.openNotification("error", "추가 실패했습니다.", res.status);
    })
    .catch((err) => {
      CommonFunc.openNotification("error", "추가 실패했습니다.", err?.response?.data);
    });
  }

  deleteLike = () => {
    const jsonData = {
      result_idx: this.state.resultIdx,
      like_idx: this.state.likeInfo?.like_idx,
    };
    FoodingService.deleteLike(JSON.stringify(jsonData))
      .then((res) => {
        if (res.status === 200) {
          CommonFunc.openNotification("success", "좋아요를 취소했습니다.", "");
          console.log("like_cnt", res.data);
          let likeCnt = res.data.rows[0]?.like_cnt;
          this.setState({
            likeCnt: likeCnt,
            isRefreshLinkList: true,
            likeInfo: null,
          });
        } else {
          // Error
          CommonFunc.openNotification("error", "잠시 후 다시 시도해주세요.", res.status);
        }
      })
      .catch((err) => {
        CommonFunc.openNotification("error", "잠시 후 다시 시도해주세요.", err?.response?.data);
      });
  }

  // 댓글이 현재 수정중인지 아닌지를 객체에 isEditComment 값을 업데이트해서 수정중인지아닌지를 체크함
  toggleIsEditComment = (comment_idx) => {
    let updatedCommentList = JSON.parse(JSON.stringify(this.state.resultCommentList));
    let index = updatedCommentList.findIndex((comment) => parseInt(comment["comment_idx"]) === parseInt(comment_idx)); // dataList 에서 issue_idx 일치하는 행의 index 추출

    updatedCommentList[index] = { ...updatedCommentList[index], isEditComment: updatedCommentList[index]?.isEditComment ? false : true };
    this.setState({
      resultCommentList: updatedCommentList,
      inputUpdateComment: updatedCommentList[index].comment,
    });
  }

  
  render() {
    const issueStateList = [...config.issueStateList];
    const { resultContents, resultTitle, resultTags, resultCommentList, resultCreateDate, resultIssueStateIdx, likeCnt, loading } = this.state;
    const editingList = resultCommentList.filter(comment => comment.isEditComment);
    return (
      <>
        {loading && 
            <div style={{ textAlign: "center", padding: "10px" }}>
              <Spin />
            </div>
        }
        {!loading && 
          <>
            <BackTop><div>▲ Top</div></BackTop>
            <Card style={{ width: '100%', maxWidth: 1200, margin: '0 auto' }}>
              <Row >
                <Col span={24}>
                  <Card>
                    <Row>
                      <Col span={18}>
                        <Title level={1} style={{ fontWeight: "normal" }}>{resultTitle}</Title>
                      </Col>
                      {
                        this.props.isAdminAuth && 
                        <Col span={2} offset={4}>
                          <div style={{ display: "flex", justifyContent: "flex-end"}}>
                          <Button
                            ghost
                            icon={<EditOutlined />}
                            type="primary"
                            shape="round"
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.history.push(`/fd/edit/result/${this.state.resultIdx}`);
                            }}
                            size="middle"
                            className="btn-new"
                          >
                            수정
                          </Button>
                        </div>
                        </Col>
                      }
                    </Row>
                    <div style={{ width: "100%", height: "10%", display: "flex", alignItems: "center" }}>
                        <Space style={{ width: "80px", paddingTop: "1px",  alignItems:"center" }}>{moment(resultCreateDate).fromNow()}</Space>
                        <Space style={{ width: "85px", paddingTop: "1px" }}>{issueStateList[resultIssueStateIdx-1]}</Space>
                        <Space style={{ width: "48px"  }}>
                          <Button
                            size="large"
                            type="text"
                            icon={
                              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                {this.state.likeInfo ? (
                                  <>
                                    <LikeTwoTone style={{ fontSize: 15 }}/>
                                    <span style={{ fontSize: 12 }}>{likeCnt}</span>
                                  </>
                                ) : (
                                  <>
                                    <LikeOutlined style={{ fontSize: 15 }} />
                                    <span style={{ fontSize: 12 }}>{likeCnt}</span>
                                  </>
                                )}
                              </div>
                            }
                            onClick={() => (this.state.likeInfo ? this.deleteLike() : this.addNewLike())}
                          />
                        </Space>
                        <Space style={{ width: "48px" }}>
                          <Button
                            size="large"
                            type="text"
                            icon={
                              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <MessageOutlined style={{ fontSize: 15 }}/>
                                <span style={{ fontSize: 12 }}>{resultCommentList?.length}</span>
                              </div>
                            }
                            onClick={() => this.commentAreaRef.current.scrollIntoView({ behavior : "smooth"})}
                          />
                        </Space>
                        <Space style={{ width: "48px" }}>
                          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <EyeOutlined style={{ fontSize: 15}} />
                            <span style={{ fontSize: 12, marginLeft: "4px" }}>{this.state.viewCnt + 1}</span>
                          </div>
                        </Space>
                    </div>
                    </Card>
                  </Col>
                  <Col span={24}>
                    <Card>
                      <MarkdownPreview style={{ padding: 10 }} source={resultContents}/>
                    </Card>
                  </Col>
                  {resultTags.length > 0 &&
                    <Col span={24}>
                      <Card  style={{ borderTop: 0 }}>
                      <div style={{ marginBottom: "4px"}}>태그</div>
                      {
                        resultTags.map((tag,index) => (
                          <Button
                            key={index}
                            ghost
                            type="primary"
                            shape="round"
                            onClick={(e) => {
                              e.preventDefault();
          
                              this.setState({
                                resultList: [],
                                searchTag: tag,
                                loading: true
                              }, () => {
                                setTimeout(async() => {
                                  const queryParams = new URLSearchParams({
                                    searchTag: tag
                                  }).toString();
                                  window.open(`/fd/list/${this.state.userId}/result?${queryParams}`, "_blank");
                                },1000);
                              })
                            }}
                            size="middle"
                            style={{
                              marginRight: "4px",
                              marginBottom: "6px",
                            }}
                            className="btn-new"
                          >
                          #{tag}
                          </Button>
                        ))
                      }
                      </Card>
                    </Col>
                  }
                  <Col span={24}>
                    <Card style={{ borderTop: 0 }} ref={this.commentAreaRef}>
                      <Space style={{ marginBottom: "4px" }}>
                        <span>댓글</span>
                        <span>
                          <MessageOutlined />
                          &nbsp;{resultCommentList?.length}
                        </span>
                      </Space>
                      <Row gutter={[0, 8]}>
                        <Col span={24}>
                          <Input.Group compact>
                            <TextArea
                              rows={1}
                              autoSize={true}
                              style={{ width: "calc(100% - 47px)" }}
                              ref={(input) => (this.resultCommentRef = input)}
                              value={this.state.inputComment}
                              onChange={(e) => {
                                this.setState({ inputComment: e.target.value });
                              }}
                            />
                            <Button type="primary" onClick={() => this.addNewComment()}>
                              등록
                            </Button>
                          </Input.Group>
                        </Col>
                        <Col span={24}>
                          {resultCommentList.map((comment) => (
                            <Row align="middle" key={comment.comment_idx}>
                              <Col span={7} >
                                <Badge dot color={comment.admin_id ? "#f50" : "#efefef"} text={comment.user_id} />
                              </Col>
                              <Col span={17}>
                                {comment.isEditComment ? (
                                  <TextArea
                                  rows={1}
                                  autoSize={true}
                                    ref={(input) => (this.ideaCommentUpdateRef = input)}
                                    value={this.state.inputUpdateComment}
                                    // onPressEnter={(e) => {
                                    //   this.ideaCommentUpdateRef.blur();
                                    // }}
                                    onBlur={(e) => {
                                      this.updateComment(comment.comment_idx);
                                    }}
                                    onChange={(e) => {
                                      this.setState({ inputUpdateComment: e.target.value });
                                    }}
                                  />
                                ) : (
                                  <div style={{ whiteSpace: "pre-line"}}>{comment.comment}</div>
                                )}
                                {/* <Button icon={<PictureTwoTone />} type="text" /> 
                                <span style={{ fontSize: 10, color: "#8A61C3" }}>&nbsp;{moment(comment.create_date).fromNow()}</span>*/}
                                <span style={{ fontSize: 10, color: "#8A61C3" }}>&nbsp;{moment(comment.create_date).from(moment())}</span>
                                {!comment.isEditComment && editingList.length === 0 && (comment.user_id === this.state.userId || this.props.isAdminAuth) && (
                                  <>
                                    <Button size="small" type="text" shape="circle" icon={<DeleteOutlined />} style={{ float: "right" }} onClick={() => this.deleteComment(comment.comment_idx)}></Button>
                                    <Button
                                      size="small"
                                      type="text"
                                      shape="circle"
                                      icon={<EditOutlined />}
                                      style={{ float: "right" }}
                                      onClick={() => this.toggleIsEditComment(comment.comment_idx)}
                                    ></Button>
                                  </>
                                )}
                              </Col>
                              <Divider style={{ height: "12px" , "marginTop": "10px", "marginBottom" : "1px"}}/>
                            </Row>
                          ))}
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
            </Card>
          </>
        }
      </>
    )
  }
}
