import React, { Component } from "react";
import { Card, Drawer, Input, Row, Col, Badge, Space, Button, Collapse, Divider, Rate } from "antd";
import { LikeOutlined, MessageTwoTone, DownOutlined, LinkOutlined, EditOutlined, CloseOutlined, DeleteOutlined, LikeTwoTone } from "@ant-design/icons";

import moment from "moment";
import "moment/locale/ko";
import IdeaDropdown from "./IdeaDropdown";
import LinkList from "./LinkList";
import UploaderWithPaste from "./UploaderWithPaste.js";
import FoodingService from "../../service/FoodingService";
import * as CommonFunc from "./functions/CommonFunc.js";

const { TextArea } = Input;
const { Panel } = Collapse;

const config = require("../../config/config");

const DRAWER_WIDTH = 700;

export default class IdeaDetailModal extends Component {
  state = {
    spaceTitle: this.props.ideaDetailData?.space_title,
    spaceIdx: this.props.ideaDetailData?.space_idx,
    ideaTitle: this.props.ideaDetailData?.idea_title,
    ideaContents: this.props.ideaDetailData?.idea_contents,
    ideaIdx: this.props.ideaDetailData?.idea_idx,
    ideaScore: this.props.ideaDetailData?.idea_score,
    linkIdx: this.props.ideaDetailData?.link_idx,
    linkCnt: this.props.ideaDetailData?.link_cnt,
    priorityIdx: this.props.ideaDetailData?.priority_idx,
    typeIdx: this.props.ideaDetailData?.idea_type_idx,
    writerUserId: this.props.ideaDetailData?.user_id,
    userId: this.props.userId,
    likeCnt: this.props.ideaDetailData?.like_cnt,
    categoryIdx: this.props.ideaDetailData?.idea_category_idx,
    feedbackIdx: this.props.ideaDetailData?.feedback_idx,
    isIdeaDetailModalVisible: this.props.isIdeaDetailModalVisible,

    isRefreshLinkList: false,

    uploadedFiles: [],
    inputComment: "",
    inputUpdateComment: "",
    commentList: [],
    likeInfo: null,

    // 모달창 Show/hide를 위한 변수
    isUploadModalVisible: null,
    uploadModalRerender: 0,

    ideaDropdownDisplay: "none",
    ideaDropdownTop: 0,
    ideaDropdownLeft: 0,
    ideaDropdownOptions: [],
    ideaDropdownOptionType: "",
    ideaDropdownValue: 0,
    ideaDropdownRerender: 0,

    isErrorInputIdeaContents: false,
  };

  /* 의견 상세 모달 추가*/
  showUploadModal = () => {
    let rerender = this.state.uploadModalRerender;
    this.setState({
      isUploadModalVisible: true,
      idea_idx: this.state.ideaIdx,
      uploadModalRerender: ++rerender,
    });
  };
  componentDidMount() {
    if (this.props.isIdeaDetailModalVisible) {
      this.getCommentList();
      this.getLikeInfo();
      this.getFileListInfo();
    }
    // 행이 바뀔 때 피드백의견도 바뀌어야 함
  }

  clickLinkListRow = ({ ideaTitle, ideaContents, ideaIdx, ideaScore, priorityIdx, typeIdx, categoryIdx, feedbackIdx, writerUserId, likeCnt }) => {
    this.setState(
      {
        ideaTitle,
        ideaContents,
        ideaIdx,
        ideaScore,
        priorityIdx,
        typeIdx,
        categoryIdx,
        feedbackIdx,
        writerUserId,
        likeCnt,
      },
      () => {
        this.getCommentList();
        this.getLikeInfo();
        this.getFileListInfo();
      }
    );
  };

  getFileListInfo = () => {
    const jsonData = {
      idea_idx: this.state.ideaIdx,
      user_id: this.state.writerUserId,
    };

    FoodingService.getFileList(JSON.stringify(jsonData))
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            uploadedFiles: res.data,
          });
        } else CommonFunc.openNotification("error", "조회를 실패했습니다.", "");
      })
      .catch((err) => {
        CommonFunc.openNotification("error", "조회를 실패했습니다.", err?.response?.data);
      });
  };
  updateIdeaDetailIdx = (key, value) => {
    if (key === "space_idx") {
      this.setState({
        spaceIdx: value,
      });
    } else if (key === "idea_category_idx") {
      this.setState({
        isRefreshLinkList: true,
        categoryIdx: value,
      });
    } else if (key === "idea_type_idx") {
      this.setState({
        isRefreshLinkList: true,
        typeIdx: value,
      });
    } else if (key === "feedback_idx") {
      var summaryCntInfo = [];
      if (value === this.state.feedbackIdx) return;

      if (value === 0) {
        if (this.state.feedbackIdx === 2 || this.state.feedbackIdx === 3) {
          summaryCntInfo.push({ idxName: "all_issue_cnt", amount: -1 });
        }
        summaryCntInfo.push({ idxName: "all_feedback_cnt", amount: -1 });
      } else {
        if ((value === 2 || value === 3) && (this.state.feedbackIdx !== 2 && !this.state.feedbackIdx !== 3)) {
          summaryCntInfo.push({ idxName: "all_issue_cnt", amount: 1 });
        }
        if ((value !== 2 && value !== 3) && (this.state.feedbackIdx === 2 || this.state.feedbackIdx === 3)) {
          summaryCntInfo.push({ idxName: "all_issue_cnt", amount: -1 });
        }
        if (this.state.feedbackIdx === 0) {
          summaryCntInfo.push({ idxName: "all_feedback_cnt", amount: 1 });
        }
      }

      this.props.reloadSummaryCnt(summaryCntInfo);

      this.setState({
        isRefreshLinkList: true,
        feedbackIdx: value,
      });
    } else if (key === "priority_idx") {

      this.setState({
        isRefreshLinkList: true,
        priorityIdx: value,
      });
    }
  };
  
  reloadIdeaData = (key, value) => {
  
    let jsonData = {
      ...this.props.ideaDetailData,

    };

    jsonData[key] = value;

    this.props.reloadIdeaList(this.state.ideaIdx, jsonData);
  };

  updateIdeaDetail = (key, value) => {
    if ((typeof value === "string" || value instanceof String) && value.trim() === "") {
      return;
    }

    if (key === "idea_score") {
      this.setState({
        ideaScore: value,
      });
    }

    this.setState({
      isRefreshLinkList: true,
    });

    //let { idea_idx, user_id } = this.props.ideaDetailData;
    let jsonData = {
      idea_idx: this.state.ideaIdx,
    };

    jsonData[key] = value;
    
    this.props.updateIdea(this.state.ideaIdx, jsonData);
  };

  closeIdeaDetailModal = () => {
    if (
      this.props.isNewEdit &&
      this.props.userId === this.state.writerUserId &&
      this.state.categoryIdx === 0 &&
      this.state.typeIdx === 0 &&
      (!this.state.ideaContents || this.state.ideaContents.trim() === "") &&
      (!this.state.ideaTitle || this.state.ideaTitle.trim() === "")
    ) {
      this.deleteIdea();
      return;
    } else {
      if ((this.state.typeIdx === 0) && this.props.userId === this.state.writerUserId) {
        CommonFunc.openNotification("error", "입력하지 않은 항목이 있습니다.", "");
        return;
      }

      if (this.props.isNewEdit && this.props.userId === this.state.writerUserId) {
        if (!this.state.ideaContents || this.state.ideaContents.trim() === "") {
          this.setState({
            isErrorInputIdeaContents: true,
          });
          CommonFunc.openNotification("error", "입력하지 않은 항목이 있습니다.", "");
          return;
        }
      }
    }

    this.setState({ isIdeaDetailModalVisible: false });

    let isNewEdit =  (this.props.isNewEdit && this.props.userId === this.state.writerUserId) && this.state.typeIdx !== 0 && (this.state.ideaContents && this.state.ideaContents !=="")

    if (isNewEdit) {
      FoodingService.sendMailForNewIdea();
    }

    this.props.changeEditState();
    this.props.removeActiveRowClass();
  };

  // 리스트에서 클릭한 위치에 IdeaDropdown 컴포넌트 표시
  showIdeaDropdown = (e, dropdownType, idea_idx, optionType, currentValue) => {
    // let xScrollPos = e.scrollLeft || document.documentElement.scrollLeft;
    // let yScrollPos = e.scrollTop || document.documentElement.scrollTop;
    this.setState({ idea_idx: idea_idx });
    this.setState({ ideaDropdownOptionType: optionType }); // category_idx, priority_idx, progress_idx, status_idx
    this.setState({ ideaDropdownOptions: dropdownType }); // config.categoryList, config.priorityList, config.progressList, config.statusList
    this.setState({ ideaDropdownValue: currentValue });
    this.setState({ ideaDropdownTop: e.clientY });

    /* 기존방식은 Drwaer에서와 방식이 달라서인지 안됨(아마 Drawer에서 찍히는 위치가 Drawer크기 구별없이 기존 사이트의 크기를 토대로 찍히는듯)
      그래서 전체 컨텐츠에서의 x값과 Drawer Width x값을 구별해서 빼줘야함

    */
    this.setState({ ideaDropdownLeft: DRAWER_WIDTH - (window.innerWidth - e.clientX) });
    this.setState({ ideaDropdownDisplay: "block" });
    let rerender = this.state.ideaDropdownRerender;
    this.setState({ ideaDropdownRerender: ++rerender });
  };

  deleteIdea = (event) => {
    let isDelete = true;

    if (event) {
      isDelete = window.confirm("의견을 삭제하시겠습니까?");
    }

    if (!isDelete) {
      return;
    }

    this.props.deleteIdea(this.state.ideaIdx);

    this.setState({ isIdeaDetailModalVisible: false });
    this.props.changeEditState();
    this.props.removeActiveRowClass();
  };

  getCommentList = () => {
    const jsonData = {
      idea_idx: this.state.ideaIdx,
      user_id: this.state.userId,
    };

    FoodingService.getCommentList(JSON.stringify(jsonData))
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            commentList: res.data?.length > 0 ? res.data : [],
          });
        } else CommonFunc.openNotification("error", "조회를 실패했습니다.", res.status);
      })
      .catch((err) => {
        CommonFunc.openNotification("error", "조회를 실패했습니다.", err?.response?.data);
      });
  };

  updateComment = (comment_idx) => {
    if (!this.state.inputUpdateComment || this.state.inputUpdateComment.trim() === "") {
      CommonFunc.openNotification("error", "변경할 댓글을 입력해주세요.");
      return;
    }
    const jsonData = {
      comment_idx,
      comment: this.state.inputUpdateComment,
    };

    FoodingService.updateComment(JSON.stringify(jsonData))
      .then((res) => {
        if (res.status === 200) {
          // 리스트의 댓글 내용 수정
          let createDate = res.data?.rows[0].create_date;
          let comment = res.data?.rows[0].comment;

          this.updateCommentData(comment_idx, createDate, comment);
          CommonFunc.openNotification("success", "댓글이 수정되었습니다.");
        } else CommonFunc.openNotification("error", "댓글 추가를 실패했습니다.", res.status);
      })
      .catch((err) => {
        CommonFunc.openNotification("error", "댓글 추가를 실패했습니다.", err?.response?.data);
      });
  };

  updateCommentData = (comment_idx, create_date, comment) => {
    let updatedCommentList = JSON.parse(JSON.stringify(this.state.commentList));
    let index = updatedCommentList.findIndex((comment) => parseInt(comment["comment_idx"]) === parseInt(comment_idx)); // dataList 에서 issue_idx 일치하는 행의 index 추출
    updatedCommentList[index] = { ...updatedCommentList[index], comment, create_date, isEditComment: false }; // index 행의 정보만 변경

    this.setState({
      commentList: updatedCommentList,
    });
  };

  deleteComment = (comment_idx) => {
    let isDelete = window.confirm("댓글을 삭제하시겠습니까?");

    if (!isDelete) {
      return;
    }
    const jsonData = {
      idea_idx: this.state.ideaIdx,
      comment_idx,
    };
    FoodingService.deleteComment(JSON.stringify(jsonData))
      .then((res) => {
        if (res.status === 200) {
          CommonFunc.openNotification("success", "댓글을 삭제했습니다.", "");

          this.setState({
            isRefreshLinkList: true,
          });
          let commentCnt = res.data[0].comment_cnt;
          this.props.reloadIdeaComment(this.state.ideaIdx, commentCnt);
          this.getCommentList();
        } else {
          // Error
          CommonFunc.openNotification("error", "댓글 삭제를 실패했습니다.", res.status);
        }
      })
      .catch((err) => {
        CommonFunc.openNotification("error", "댓글 삭제를 실패했습니다.", err?.response?.data);
      });
  };

  addNewComment = () => {
    if (!this.state.inputComment || this.state.inputComment.trim() === "") {
      CommonFunc.openNotification("error", "댓글을 입력해주세요.");
      return;
    }

    const jsonData = {
      idea_idx: this.state.ideaIdx,
      space_idx: this.state.spaceIdx,
      user_id: this.state.userId,
      comment: this.state.inputComment,
      writerUserId: this.state.writerUserId,
      isAdmin: this.props.isAdminAuth
    };
    FoodingService.addNewComment(JSON.stringify(jsonData))
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            inputComment: "",
            isRefreshLinkList: true,
          });
          CommonFunc.openNotification("success", "댓글이 추가되었습니다.");

          this.props.reloadIdeaComment(this.state.ideaIdx, res.data[0]?.comment_cnt);
          this.getCommentList();
        } else CommonFunc.openNotification("error", "댓글 추가를 실패했습니다.", res.status);
      })
      .catch((err) => {
        CommonFunc.openNotification("error", "댓글 추가를 실패했습니다.", err?.response?.data);
      });
  };

  getLikeInfo = () => {
    const jsonData = {
      idea_idx: this.state.ideaIdx,
      user_id: this.props.isAdminAuth ? "admin" : this.state.userId,
    };
    FoodingService.getLikeInfo(JSON.stringify(jsonData))
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            likeInfo: res.data[0],
          });
        } else CommonFunc.openNotification("error", "조회를 실패했습니다.", res.status);
      })
      .catch((err) => {
        CommonFunc.openNotification("error", "조회를 실패했습니다.", err?.response?.data);
      });
  };

  addNewLike = () => {
    const jsonData = {
      idea_idx: this.state.ideaIdx,
      user_id: this.state.userId,
    };

    FoodingService.addNewLike(JSON.stringify(jsonData))
      .then((res) => {
        if (res.status === 200) {
          let likeCnt = res.data.rows[0]?.like_cnt;
          CommonFunc.openNotification("success", "좋아요를 눌렀습니다.");

          // 좋아요 카운트랑 좋아요 정보 state로 초기화 - 좋아요 정보는 현재 좋아요 누른 사용자의 정보임 이걸로 나중에 삭제하는데 사용
          this.setState({
            likeCnt,
            likeInfo: res.data.likeInfo,
            isRefreshLinkList: true,
          });

          // 리스트의 개수 변경
          this.props.reloadIdeaLike(this.state.ideaIdx, likeCnt);
        } else CommonFunc.openNotification("error", "추가 실패했습니다.", res.status);
      })
      .catch((err) => {
        CommonFunc.openNotification("error", "추가 실패했습니다.", err?.response?.data);
      });
  };

  deleteLike = () => {
    const jsonData = {
      idea_idx: this.state.ideaIdx,
      like_idx: this.state.likeInfo?.like_idx,
    };
    FoodingService.deleteLike(JSON.stringify(jsonData))
      .then((res) => {
        if (res.status === 200) {
          CommonFunc.openNotification("success", "좋아요를 취소했습니다.", "");
          console.log("like_cnt", res.data);
          let likeCnt = res.data.rows[0]?.like_cnt;
          this.setState({
            likeCnt: likeCnt,
            isRefreshLinkList: true,
            likeInfo: null,
          });
          this.props.reloadIdeaLike(this.state.ideaIdx, likeCnt);
        } else {
          // Error
          CommonFunc.openNotification("error", "잠시 후 다시 시도해주세요.", res.status);
        }
      })
      .catch((err) => {
        CommonFunc.openNotification("error", "잠시 후 다시 시도해주세요.", err?.response?.data);
      });
  };

  // 링크리스트 초기화용 flag 변수, 상세화면에서 데이터 수정 시 링크리스트 다시 불러온다.
  clearIsRefreshLinkList = () => {
    this.setState({
      isRefreshLinkList: false,
    });
  };

  // 링크 카운트가 초기화 될때 카운트 값을 받아서 여기 state로 초기화
  updateLinkCntInDetail = (linkCnt) => {
    this.setState({
      linkCnt,
    });
  };

  // 댓글이 현재 수정중인지 아닌지를 객체에 isEditComment 값을 업데이트해서 수정중인지아닌지를 체크함
  toggleIsEditComment = (comment_idx) => {
    let updatedCommentList = JSON.parse(JSON.stringify(this.state.commentList));
    let index = updatedCommentList.findIndex((comment) => parseInt(comment["comment_idx"]) === parseInt(comment_idx)); // dataList 에서 issue_idx 일치하는 행의 index 추출

    updatedCommentList[index] = { ...updatedCommentList[index], isEditComment: updatedCommentList[index]?.isEditComment ? false : true };
    this.setState({
      commentList: updatedCommentList,
      inputUpdateComment: updatedCommentList[index].comment,
    });
  };

  
  render() {
    const productNameOptions = [...config.productNameList];
    const problemTypeOptions = [...config.problemTypeList, "[공백]"];
    const priorityOptions = [...config.priorityList, "[공백]"];
    const feedbackOptions = [...config.feedbackList];
    const editingList = this.state.commentList.filter(comment => comment.isEditComment);
    return (
      <Drawer
        title={
          <>
            {this.props.isAdminAuth ?  
              <Space size={"middle"}>
                <div
                  onClick={(e) => this.showIdeaDropdown(e, this.props.spaceList, this.state.spaceIdx, "space_idx", this.state.spaceIdx)}
                  style={{ cursor: "pointer", color: this.state.typeIdx === 0 ? "red" : "black"}}
                >
                  <div>
                    {this.props.spaceList.filter((space) => space.space_idx === this.state.spaceIdx)[0]?.space_title} <DownOutlined style={{ fontSize: "9px" }} />
                  </div>
                </div>
              </Space>
              :
              this.state.spaceTitle
            }
            <Button onClick={this.closeIdeaDetailModal} style={{ float: "right", borderWidth: 0 }} icon={<CloseOutlined />} />
          </>
        }
        width={DRAWER_WIDTH}
        placement="right"
        closable={false}
        onClose={this.closeIdeaDetailModal}
        visible={this.state.isIdeaDetailModalVisible}
        key={"right"}
        footer={
          ((this.props.userId === this.state.writerUserId && ((this.state.ideaContents && this.state.ideaContents.trim() !== "") || this.state.categoryIdx !== 0 || this.state.typeIdx !== 0)) ||
            this.props.isAdminAuth) && (
            <Button danger onClick={this.deleteIdea}>
              삭제
            </Button>
          )
        }
      >
        <Row gutter={[0,0]}>
          <Col span={24}>
            <Collapse defaultActiveKey={["1"]}>
              <Panel
                header={
                  <Space>
                    <span style={{ fontSize: 14 }}>제안 의견</span>
                  </Space>
                }
                key="1"
              >
                <Card>
                  <Row justify="center" align="middle">
                    <Col span={6}>
                      <Space size={"middle"}>
                        <span>제품명 : </span>
                        {this.state.writerUserId === this.props.userId || this.props.isAdminAuth ? (
                        <div
                          onClick={(e) => this.showIdeaDropdown(e, productNameOptions, this.props.ideaDetailData?.idea_idx, "idea_type_idx", this.state.typeIdx)}
                          style={{ cursor: "pointer", color: this.state.typeIdx === 0 ? "red" : "black" }}
                        >
                          <div>
                            {this.state.typeIdx === 0 ? "[선택]" : productNameOptions[this.state.typeIdx - 1]} <DownOutlined style={{ fontSize: "9px" }} />
                          </div>
                        </div>
                      ) : (
                        <div>{this.state.typeIdx === 0 ? "" : productNameOptions[this.state.typeIdx - 1]}</div>
                      )}
                      </Space>
                    </Col>
                    <Col span={16}>
                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                          <div style={{ width: "28%"}}><span>메뉴명 또는 화면 위치 : </span></div>
                    {this.state.writerUserId === this.props.userId || this.props.isAdminAuth ? (
                          <Input
                            type="text"
                            className="test-input"
                            placeholder="메뉴명 또는 화면위치를 적어주세요."
                            style={{ backgroundColor: "white", width: "72%" }}
                            ref={(input) => (this.ideaTitleRef = input)}
                            value={this.state.ideaTitle}
                            onPressEnter={(e) => {
                              this.ideaTitleRef.blur();
                            }}
                            onBlur={(e) => {
                              this.updateIdeaDetail("idea_title", e.target.value);
                            }}
                            onChange={(e) => {
                              this.setState({ ideaTitle: e.target.value });
                            }}
                            />
                    ) : (
                      <>
                        {this.state.ideaTitle && (<div style={{ width: "85%", height: "50%" }}>{this.state.ideaTitle}</div>)}
                      </>
                        )}
                        </div>
                    </Col>
                    <Col span={2}>
                    {this.state.writerUserId === this.props.userId && (
                      <Button
                        size="large"
                        type="text"
                        style={{ position: "absolute", display: "flex", cursor: "default" }}
                        icon={
                          <Space style={{ position: "absolute", display: "flex", top: -16, right: -15 }}>
                            {this.state.likeInfo ? (
                              <>
                                <LikeTwoTone />
                                <span style={{ fontSize: 12 }}>{this.state.likeCnt}</span>
                              </>
                            ) : (
                              <>
                                <LikeOutlined />
                                <span style={{ fontSize: 12 }}>{this.state.likeCnt}</span>
                              </>
                            )}
                          </Space>
                        }
                      />
                    )}
                    {this.state.writerUserId !== this.props.userId && (
                      <Button
                        size="large"
                        type="text"
                        icon={
                          <Space>
                            {this.state.likeInfo ? (
                              <>
                                <LikeTwoTone />
                                <span style={{ fontSize: 12 }}>{this.state.likeCnt}</span>
                              </>
                            ) : (
                              <>
                                <LikeOutlined />
                                <span style={{ fontSize: 12 }}>{this.state.likeCnt}</span>
                              </>
                            )}
                          </Space>
                        }
                        style={{ position: "absolute", display: "flex", top: -16, right: 8 }}
                        onClick={() => (this.state.likeInfo ? this.deleteLike() : this.addNewLike())}
                      />
                        )}
                    {/*
                    * 좋아요 Button 클릭하면 icon <LikeTwoTone /> 으로 바뀜.
                    */}
                    </Col>
                  </Row>
                </Card>
                <Card style={{ borderTop: 0, height: "430px" }}>
                  {this.state.writerUserId === this.props.userId || this.props.isAdminAuth ? (
                    <TextArea
                      rows={20}
                      style={{ marginBottom: 4, backgroundColor: this.state.isErrorInputIdeaContents ? "#FFC2D1" : "white", resize: "none" }}
                      value={this.state.ideaContents}
                      ref={(input) => (this.ideaContentsRef = input)}
                      placeholder="내용을 적어주세요."
                      onBlur={(e) => {
                        this.updateIdeaDetail("idea_contents", e.target.value);
                      }}
                      onChange={(e) => {
                        this.setState({ ideaContents: e.target.value, isErrorInputIdeaContents: false });
                      }}
                    />
                  ) : (
                    <div style={{ height: "430px" }}>
                      {/* <MarkdownPreview source={this.state.ideaContents} /> */}
                      {this.state.ideaContents?.split("\n").map((line, index) => {
                        return (
                          <span key={`line${index}`}>
                            {line}
                            <br />
                          </span>
                        );
                      })}
                    </div>
                  )}
                </Card>
                { ((this.state.uploadedFiles && this.state.uploadedFiles.length > 0 ) || (this.state.writerUserId === this.props.userId || this.props.isAdminAuth ) ) &&
                  <Card style={{ borderTop: 0 }}>
                    <UploaderWithPaste key={this.state.ideaIdx} writerUserId={this.state.writerUserId} ideaIdx={this.state.ideaIdx} userId={this.props.userId} isAdmin={this.props.isAdminAuth}/>
                  </Card>
                }
                {(!this.props.isNewEdit || this.props.isAdminAuth) && (
                  <Card style={{ borderTop: 0 }}>
                    <Space>
                      <span>댓글</span>
                      <span>
                        <MessageTwoTone />
                        &nbsp;{this.state.commentList?.length}
                      </span>
                    </Space>
                    <Row gutter={[0, 8]}>
                      <Col span={24}>
                        {this.state.commentList.map((comment) => (
                          <Row align="middle" key={comment.comment_idx}>
                            <Col span={7} >
                              <Badge dot color={comment.admin_id ? "#f50" : "#efefef"} text={comment.user_id} />
                            </Col>
                            <Col span={17}>
                              {comment.isEditComment ? (
                                <TextArea
                                rows={1}
                                autoSize={true}
                                  ref={(input) => (this.ideaCommentUpdateRef = input)}
                                  value={this.state.inputUpdateComment}
                                  // onPressEnter={(e) => {
                                  //   this.ideaCommentUpdateRef.blur();
                                  // }}
                                  onBlur={(e) => {
                                    this.updateComment(comment.comment_idx);
                                  }}
                                  onChange={(e) => {
                                    this.setState({ inputUpdateComment: e.target.value });
                                  }}
                                />
                              ) : (
                                <div style={{ whiteSpace: "pre-line"}}>{comment.comment}</div>
                              )}
                              {/* <Button icon={<PictureTwoTone />} type="text" /> 
                              <span style={{ fontSize: 10, color: "#8A61C3" }}>&nbsp;{moment(comment.create_date).fromNow()}</span>*/}
                              <span style={{ fontSize: 10, color: "#8A61C3" }}>&nbsp;{moment(comment.create_date).from(moment())}</span>
                              {/* 업로드 된 사진 보는 Button */}
                              {!comment.isEditComment && editingList.length === 0 && (comment.user_id === this.props.userId || this.props.isAdminAuth) && (
                                <>
                                  <Button size="small" type="text" shape="circle" icon={<DeleteOutlined />} style={{ float: "right" }} onClick={() => this.deleteComment(comment.comment_idx)}></Button>
                                  <Button
                                    size="small"
                                    type="text"
                                    shape="circle"
                                    icon={<EditOutlined />}
                                    style={{ float: "right" }}
                                    onClick={() => this.toggleIsEditComment(comment.comment_idx)}
                                  ></Button>
                                </>
                              )}
                            </Col>
                            <Divider style={{ height: "12px" , "marginTop": "10px", "marginBottom" : "1px"}}/>
                          </Row>
                        ))}
                      </Col>
                      <Col span={24}>
                        <Input.Group compact>
                          <TextArea
                            rows={1}
                            autoSize={true}
                            style={{ width: "calc(100% - 47px)" }}
                            ref={(input) => (this.ideaCommentRef = input)}
                            value={this.state.inputComment}
                            onChange={(e) => {
                              this.setState({ inputComment: e.target.value });
                            }}
                          />
                          <Button type="primary" onClick={() => this.addNewComment()}>
                            등록
                          </Button>
                        </Input.Group>
                      </Col>
                    </Row>
                  </Card>
                )}
                {!this.props.isNewEdit && (this.state.feedbackIdx !== 0 || this.state.ideaScore !== 0 || this.state.priorityIdx !== 0 || this.props.isAdminAuth) && (
                  <Card style={{ borderTop: 0 }}>
                    <Row justify="space-between" align="small">
                      <Col span={21.5}>
                        <Space size={"small"}><span>검토 현황 : </span>
                          {this.props.isAdminAuth ? (
                            <div onClick={(e) => this.showIdeaDropdown(e, feedbackOptions, this.props.ideaDetailData?.idea_idx, "feedback_idx", this.state.feedbackIdx)} style={{ cursor: "pointer" }}>
                              <div>
                                {this.state.feedbackIdx === 0 ? "미확인" : feedbackOptions[this.state.feedbackIdx ]} <DownOutlined style={{ fontSize: "9px" }} />
                              </div>
                            </div>
                          ) : (
                            <div>{this.state.feedbackIdx === 0 ? "미확인" : feedbackOptions[this.state.feedbackIdx ]}</div>
                          )}
                          {this.props.isAdminAuth &&
                            <>
                              <span>의견 분류 : </span>
                              <div onClick={(e) => this.showIdeaDropdown(e, problemTypeOptions, this.props.ideaDetailData?.idea_idx, "idea_category_idx", this.state.categoryIdx)} style={{ cursor: "pointer" }}>
                                <div>
                                 {this.state.categoryIdx === 0 ? "[공백]" : problemTypeOptions[this.state.categoryIdx  - 1]} <DownOutlined style={{ fontSize: "9px" }} />
                                </div>
                              </div>
                            </>
                          }

                          {/* <Select
                          defaultValue={this.state.feedbackIdx}
                          style={{ width: 80 }}
                          onChange={(value) => {
                            this.updateIdeaDetail("feedback_idx", value);
                          }}
                        >
                          {feedbackOptions.map((label, index) => (
                            <Select.Option value={label === "[공백]" ? 0 : index + 1}>{label}</Select.Option>
                          ))}
                        </Select> */}
                          <span>기여도 : </span>
                          <span>
                            {this.props.isAdminAuth ? (
                              <Rate
                              style={{fontSize: 9 }} 
                                allowClear={true}
                                value={this.state.ideaScore}
                                onChange={(value) => {
                                  this.updateIdeaDetail("idea_score", value);
                                }}
                              />
                            ) : (
                              <Rate value={this.state.ideaScore === 0 ? 5 : this.state.ideaScore} disabled style={{ color: this.state.ideaScore === 0 && "white" }} />
                            )}
                          </span>
                          <span>중요도 : </span>
                          {this.props.isAdminAuth ? (
                            <div onClick={(e) => this.showIdeaDropdown(e, priorityOptions, this.props.ideaDetailData?.idea_idx, "priority_idx", this.state.priorityIdx)} style={{ cursor: "pointer" }}>
                              {this.state.priorityIdx === 0 && (
                                <div>
                                  [공백] <DownOutlined style={{ fontSize: "9px" }} />
                                </div>
                              )}
                              {this.state.priorityIdx === 3 && (
                                <div>
                                  <Badge dot color="#efefef" text="낮음" />
                                  <DownOutlined style={{ fontSize: "9px" }} />
                                </div>
                              )}
                              {this.state.priorityIdx === 2 && (
                                <div>
                                  <Badge dot color="#87d068" text="보통" />
                                  <DownOutlined style={{ fontSize: "9px" }} />
                                </div>
                              )}
                              {this.state.priorityIdx === 1 && (
                                <div>
                                  <Badge dot color="#f50" text="높음" />
                                  <DownOutlined style={{ fontSize: "9px" }} />
                                </div>
                              )}
                            </div>
                          ) : (
                            <div>
                              {this.state.priorityIdx === 0 && ""}
                              {this.state.priorityIdx === 3 && <Badge dot color="#efefef" text="낮음" />}
                              {this.state.priorityIdx === 2 && <Badge dot color="#87d068" text="보통" />}
                              {this.state.priorityIdx === 1 && <Badge dot color="#f50" text="높음" />}
                            </div>
                          )}
                          {/* <Select
                          defaultValue={this.state.priorityIdx}
                          style={{ width: 80 }}
                          onChange={(value) => {
                            this.updateIdeaDetail("priority_idx", value);
                          }}
                        >
                          <Select.Option value="낮음">
                            <Badge dot color="#52C41A" text="낮음" />
                          </Select.Option>
                          <Select.Option value={2}>
                            <Badge dot color="#FA8C16" text="보통" />
                          </Select.Option>
                          <Select.Option value={1}>
                            <Badge dot color="#F5222D" text="높음" />
                          </Select.Option>
                          <Select.Option value={0}>[공백]</Select.Option>
                        </Select> */}
                        </Space>
                      </Col>
                      <Col span={2.5}>{this.props.isAdminAuth && <span>의견 번호 : {this.state.ideaIdx}</span>}</Col>
                    </Row>
                  </Card>
                )}
              </Panel>
              {(this.state.linkCnt > 0 || this.props.isAdminAuth) && (
                <Panel
                  header={
                    <Space>
                      <span style={{ fontSize: 14 }}>
                        링크된 의견
                        <Button icon={<LinkOutlined />} style={{ cursor: "default" }} type="link"></Button>
                        &nbsp;{this.state.linkCnt}
                      </span>
                    </Space>
                  }
                  key="2"
                >
                  <Card>
                    <LinkList
                      ideaIdx={this.state.ideaIdx}
                      updateLinkCntInDetail={this.updateLinkCntInDetail}
                      linkIdx={this.state.linkIdx}
                      reloadIdeaLink={this.props.reloadIdeaLink}
                      isRefreshLinkList={this.state.isRefreshLinkList}
                      clearIsRefreshLinkList={this.clearIsRefreshLinkList}
                      clickLinkListRow={this.clickLinkListRow}
                      isAdminAuth={this.props.isAdminAuth}
                    />
                  </Card>
                </Panel>
              )}
            </Collapse>
          </Col>
        </Row>
        <IdeaDropdown
          key={"IdeaDropdown" + this.state.ideaDropdownRerender}
          idea_idx={this.props.ideaDetailData?.idea_idx}
          ideaDropdownOptions={this.state.ideaDropdownOptions}
          ideaDropdownOptionType={this.state.ideaDropdownOptionType}
          ideaDropdownValue={this.state.ideaDropdownValue}
          ideaDropdownDisplay={this.state.ideaDropdownDisplay}
          ideaDropdownTop={this.state.ideaDropdownTop}
          ideaDropdownLeft={this.state.ideaDropdownLeft}
          updateIdeaData={this.updateIdeaDetail}
          updateIdeaDetailIdx={this.updateIdeaDetailIdx}
        />
      </Drawer>
    );
  }
}
