
import React, { createContext, useState, useEffect } from 'react';
import FoodingService from '../service/FoodingService';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAdminAuth, setIsAdminAuth] = useState(false);

  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async (checkToken, isFirstLogin = false) => {
    const token = isFirstLogin ? checkToken : localStorage.getItem('token');
    
    if (token) {
      try {
        const res = await FoodingService.checkAuthUser(token, isFirstLogin);
        
        if (res.data.success) {
          let user = res.data.user;
          setIsAuthenticated(true);
          setUser(user);
          setLoading(false);
          if (user.auth === "admin") setIsAdminAuth(true);
          return user.userToken;
         } else {
          localStorage.removeItem('token');
        }
      } catch (error) {
        localStorage.removeItem('token');
      }
    }
    setLoading(false);
    return null;
  };

  const login = async (token, isAdmin = false) => {
    // 관리자 로그인 하는 경우
    if (isAdmin) {
      localStorage.setItem('token', token);
      setIsAuthenticated(true);
      setIsAdminAuth(isAdmin);
      return true;
    } else {
      // 일반 사용자는 일회성 토큰을 넘겨 한번더 체크 후에 받은 토큰으로 로그인을 관리
      let userToken = await checkAuth(token, true);
      console.log(userToken)
      if (userToken) {
        localStorage.setItem('token', userToken);
        setIsAuthenticated(true);
        return true;
      } else {
        return false;
      }
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        login,
        logout,
        isAdminAuth
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
