import React, { useRef, useCallback, useState, useEffect } from "react";
import {
  useItemFinishListener,
  useItemProgressListener,
  useRequestPreSend,
} from "@rpldy/uploady";
import UploadButton from "@rpldy/upload-button";
import { usePasteUpload } from "@rpldy/upload-paste";
import { Input } from "antd";
import { FileImageTwoTone } from "@ant-design/icons";
import * as CommonFunc from "../../common/CommonFunc.js";
import UploadedFilePreviewArea from "./UploadedFilePreviewArea.js";
import FoodingService from "../../service/FoodingService";

const UploaderPasteArea = (props) => {
  const containerRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false); // Input Box 업로드 영역 활성/비활성 상태 관리
  const [uploadedFiles, setUploadedFiles] = useState([]); // 파일 목록 관리
  const [progressRate, setProgressRate] = useState(0);
  const [progressStatus, setProgressStatus] = useState(0);

  useEffect(() => {
    const jsonData = {
      idea_idx: props.ideaIdx,
      user_id: props.writerUserId,
    };
    FoodingService.getFileList(JSON.stringify(jsonData))
      .then((res) => {
        if (res.status === 200) {
          if (res.data !== "") {
            setUploadedFiles(res.data);
          }
        } else {
          CommonFunc.openNotification("error", "조회를 실패했습니다.", "");
        }
      })
      .catch((err) => {
        CommonFunc.openNotification("error", "조회를 실패했습니다.", err?.response?.data);

      });
  }, [props.ideaIdx, props.writerUserId]);

  /**
   * onDeleteCompleted : 이미지 삭제 버튼 클릭 이벤트 처리
   * desc : <UploadedFilePreviewArea/> 컴포넌트에 전달되는 함수
   * pramas :
   *    fileName - 삭제된 이미지
   *    uploadedFiles - 이미지 목록 (현재 있는 함수의 state를 사용할 수 없어 하위 컴포넌트로 전달 후 다시 전달 받아야 함)
   * info : state를 변경 하더라도 PreviewArea 컴포넌트가 릴로드 되지 않아 마지막에 setIsFocused() 를 통해 강제 릴로드 처리.
   */
  const onDeleteCompleted = useCallback((fileName, uploadedFiles) => {
    let newUploadedFiles = uploadedFiles;
    const index = newUploadedFiles.findIndex(function (item) {
      return item === fileName;
    });
    if (index > -1) newUploadedFiles.splice(index, 1);
    setUploadedFiles([...newUploadedFiles]);
    setIsFocused(true);
    setIsFocused(false);
  }, []);

  /**
   * useItemProgressListener : 이미지 업로드 프로그래스바 이벤트 처리
   * desc : 파일이 업로드 되는 동안 프로그래스 상태 정보를 처리
   * pramas :
   *    item - 파일 정보
   * info : UploadedFilePreviewArea.js 파일로 props를 전달하여 프로그래스 UI 처리를 수행.
   */
  useItemProgressListener((item) => {
    setProgressStatus(1);
    setProgressRate(item.completed);
    if (item.completed === 100) {
      setProgressStatus(0);
    }
    /*console.log(`item ${item.id} is ${item.completed}% done and ${item.loaded} bytes uploaded`);*/
  });

  /**
   * useItemFinishListener : 이미지 업로드 완료 이벤트 처리
   * desc : 업로드가 완료되면 호출됨.
   * pramas :
   *    result - <Uploady destination="url"/> 에 의해 처리된 결과 값
   * info : result 객체내의 uploadResponse 객체에 http Response 내용이 들어 있음. 이 값을 이용하여 이미지 목록 관리.
   */
  useItemFinishListener((result) => {
    // 업로드 완료 후 파일 목록 State 정보 갱신
    setUploadedFiles((uploadedFiles) => [
      ...uploadedFiles,
      result.uploadResponse.data.data.fileName,
    ]);
    CommonFunc.openNotification("success", "업로드가 완료됐습니다.", "");
  });

  /**
   * useRequestPreSend : 이미지 전송 전 이벤트 처리
   * 한글 파일명이 깨지는 이슈로 인해 encodeURIComponent 로 변환된 이름을 별도로 전달하고 서버에서 decode 해서 사용.
   */
  useRequestPreSend(({ items, options }) => {
    //console.log(options);
    return {
      options: {
        params: {
          encodedFileName: encodeURIComponent(items[0].file.name),
        },
        /* destination: {
            url: "http://127.0.0.1:3000/fileUpload",
          }, */
      },
    };
  });

  // copy & paste 기능 사용
  usePasteUpload(props, containerRef, null);

  // 이미지 수정 팝업 닫기 한 경우 이미지 수정사항이 발생했을 수 있으니 파일 목록 다시 그리기
  const onEditCompleted = useCallback((changedFiles) => {
    setUploadedFiles([...changedFiles]);
    setIsFocused(true);
    setIsFocused(false);
  }, []);

  return (
    <>
      <div ref={containerRef}>
        {(props.userId === props.writerUserId || props.isAdmin) && 
          <div style={{ display: "flex", width: "100%" }}>
            <Input
              prefix={<FileImageTwoTone />}
              value={
                isFocused
                  ? "붙여 넣기 가능"
                  : "이 영역을 선택하시면 스크린샷을 붙여넣기 할 수 있습니다."
              }
              readOnly={true}
              onFocus={() => {
                setIsFocused(true);
              }}
              onBlur={() => {
                setIsFocused(false);
              }}
            ></Input>
            <UploadButton
              text="파일 직접 선택"
              className="ant-btn ant-btn-primary"
              params={{ idea_idx: props.ideaIdx, user_id: props.writerUserId }}
              style={{ width: "100%" }}
            />
          </div>
        }
        <UploadedFilePreviewArea
          key={props.ideaIdx}
          ideaIdx={props.ideaIdx}
          userId={props.userId}
          writerUserId={props.writerUserId}
          uploadedFiles={uploadedFiles}
          isAdmin={props.isAdmin}
          onDeleteCompleted={onDeleteCompleted}
          onEditCompleted={onEditCompleted}
          progressRate={progressRate}
          progressStatus={progressStatus}
          imageType={props.imageType}
        />
      </div>
    </>
  );
};

export default UploaderPasteArea;
