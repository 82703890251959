import React, { Component } from 'react';
import { Upload, Button, Row, Col, Card, Input, Modal, Space, Radio, Tag } from 'antd';
import { PlusOutlined, SaveOutlined, EyeOutlined, DownOutlined,ZoomInOutlined, DeleteOutlined, PictureOutlined, StarOutlined, StarTwoTone } from '@ant-design/icons';
import FoodingService from "../service/FoodingService";
import * as CommonFunc from "./components/functions/CommonFunc.js";
import IdeaDropdown from "./components/IdeaDropdown";
import MarkdownPreview from "@uiw/react-markdown-preview";
import "moment/locale/ko";
import "antd/dist/antd.compact.css";
import axios from 'axios';
import UploadFileViewer from "./components/UploadFileViewer";
import UploadFileEditor from "./components/UploadFileEditor";

const config = require("../config/config.js");
const { TextArea } = Input;

export default class FoodingResultEditor extends Component { 
  state = {
    userId: "",
    fileList: [],
    cursorPosition: 0,
    previewVisible: false,
    resultIdx: this.props?.match?.params.resultIdx,
    resultTitle: "",
    resultContents: "",
    isShowResult: true,
    resultIssueStateIdx: 0,
    ideaDropdownDisplay: "none",
    ideaDropdownTop: 0,
    ideaDropdownLeft: 0,
    ideaDropdownOptions: [],
    ideaDropdownOptionType: "",
    ideaDropdownValue: 0,
    ideaDropdownRerender: 0,
    
    resultTags: [],
    inputVisible: false, // 태그 입력 필드 표시 여부
    inputValue: "", // 태그 입력값
    currentState:  this.props?.match?.params.resultIdx ? "update" : "insert",
    isUpload: false,


    isUplodFileViewerVisible: false,
    isUplodFileEditorVisible: false,
    viewerModalRerender: 0,
    editorModalRerender: 0,
    viewFileName: "",
    imageCacheRenderer: 0
  }

  imageBase64Cache = new Map();
  editorRef = React.createRef();
  inputRef = React.createRef();
  loadBtnRef = React.createRef();
  saveBtnRef = React.createRef();
  autoSaveTimer = null;

  componentDidMount () {
    let resultIdx = this.props?.match?.params.resultIdx;
    if (resultIdx) {
      this.findResultDetail(resultIdx);
    } else {
      this.handleSave();
    }
    clearInterval(this.autoSaveTimer);

    // 3분마다 자동 저장
    this.autoSaveTimer = setInterval(() => {
        this.saveCurrentDocument();
    }, 300000);
    document.addEventListener('paste', this.handlePaste);
    const params = new URLSearchParams(window.location.search);

    this.setState({
      userId:params.get('userId')
    });
  }

  componentWillUnmount() {
    document.removeEventListener('paste', this.handlePaste);
    
    clearInterval(this.autoSaveTimer);
    this.autoSaveTimer = null;
  }

  findResultDetail = async (result_idx) => {
    let listFilterParams = {
        user_id: this.state.userId,
        isAdmin: true,
        page_current: 1,
        page_size: 1,
        result_idx
    };
    
    let response = await FoodingService.getResultList(JSON.stringify(listFilterParams));
    let responseData = response.data[0];
    if (responseData) {
      let fileList = JSON.parse(responseData.result_images);
      // fileList.map(file =>  this.imageBase64Cache.set(file.uid, file.base64));
      this.setState({
        resultIdx: responseData.result_idx,
        resultTitle: responseData.result_title,
        resultContents: responseData.result_contents,
        resultIssueStateIdx: responseData.result_issue_state_idx,
        resultCreateDate: responseData.create_date,
        resultTags: JSON.parse(responseData.result_tags),
        isShowResult: responseData.is_show_result ? true : false,
        fileList,
      });
    }
  }

  updateIdeaDetail = (key, value) => {
    if ((typeof value === "string" || value instanceof String) && value.trim() === "") {
      return;
    }

    if (key === "issue_state_idx") {
      this.setState({
        resultIssueStateIdx: value,
      });
    }

    this.setState({
      isRefreshLinkList: true,
    });

    //let { idea_idx, user_id } = this.props.ideaDetailData;
    let jsonData = {
      idea_idx: this.state.ideaIdx,
    };

    jsonData[key] = value;
    
    // this.props.updateIdea(this.state.ideaIdx, jsonData);
  };

  updateIdeaDetailIdx = (key, value) => {
    if (key === "issue_state_idx") {
      this.setState({
        resultIssueStateIdx: value,
      });
    }
  };
  
  // 문서 저장 함수
  saveCurrentDocument = async () => {
    const { title, resultContents, fileList, resultIdx } = this.state;
    
    this.loadBtnRef.current.disabled = false;
      // fileList.map(file => {
      //   console.log(this.imageBase64Cache.get(file.uid))
      // })
    try {
      // 저장할 문서 데이터 구성
      const documentData = {
        title,
        content: resultContents,
        images: fileList.map(file => ({
          name: file.name,
          uid: file.uid,
          isDefault: file.isDefault
        })),
        lastModified: new Date().toISOString(),
        resultIdx
      };
      // const documentData = {
      //   title,
      //   content: resultContents,
      //   images: fileList.map(file => ({
      //     name: file.name,
      //     base64: this.imageBase64Cache.get(file.uid),
      //     uid: file.uid,
      //     isDefault: file.isDefault
      //   })),
      //   lastModified: new Date().toISOString()
      // };
      // TODO: 실제 서버에 저장하는 API 호출
      // console.log('저장할 문서 데이터:', documentData);
      
      // 임시로 localStorage에 저장
      localStorage.setItem("blog-draft", JSON.stringify(documentData));
      
      CommonFunc.openNotification("success", "문서가 임시저장 되었습니다.", "");
    } catch (error) {
      console.error('저장 실패:', error);
      CommonFunc.openNotification("error", "문서 저장에 실패했습니다.", "");
    }
  }

  /* 의견 상세 모달 추가*/
  showIdeaDetailModal = (idea_idx, record) => {
    // 의견 모달 제목 무조건 넣게 수정
    if (record) {
      record.space_title = record.space_title ? record.space_title : this.state.selectedSpaceInfo?.space_title;
    }
    let rerender = this.state.ideaDetailModalRerender;
    this.setState({
      isIdeaDetailModalVisible: true,
      idea_idx: idea_idx,
      ideaDetailModalRerender: ++rerender,
      ideaDetailData: record,
    });
  };
  
  // 리스트에서 클릭한 위치에 IdeaDropdown 컴포넌트 표시
  showIdeaDropdown = (e, dropdownType, idea_idx, optionType, currentValue) => {
    // let xScrollPos = e.scrollLeft || document.documentElement.scrollLeft;
    // let yScrollPos = e.scrollTop || document.documentElement.scrollTop;
    // this.setState({ resultIssueStateIdx: idea_idx });
    this.setState({ ideaDropdownOptionType: optionType }); // category_idx, priority_idx, progress_idx, status_idx
    this.setState({ ideaDropdownOptions: dropdownType }); // config.categoryList, config.priorityList, config.progressList, config.statusList
    this.setState({ ideaDropdownValue: currentValue });
    this.setState({ ideaDropdownTop: e.clientY });

    /* 
      기존방식은 Drwaer에서와 방식이 달라서인지 안됨(아마 Drawer에서 찍히는 위치가 Drawer크기 구별없이 기존 사이트의 크기를 토대로 찍히는듯)
      그래서 전체 컨텐츠에서의 x값과 Drawer Width x값을 구별해서 빼줘야함

    */
    this.setState({ ideaDropdownLeft: e.clientX });
    this.setState({ ideaDropdownDisplay: "block" });
    let rerender = this.state.ideaDropdownRerender;
    this.setState({ ideaDropdownRerender: ++rerender });
  };

  // ImageEditor 이미지 Blob 변환
  dataURItoBlob = (dataURI, mimeString) => {
    var byteString = atob(dataURI.split(",")[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  };

  // 파일을 base64로 변환하는 유틸리티 함수
  convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
      reader.readAsDataURL(file);
    });
  };

  handleDeleteImage = (file) => {
    const newFileList = this.state.fileList.filter(item => item.uid !== file.uid);
    // this.imageBase64Cache.delete(file.uid);
    this.setState({ fileList: newFileList });
    CommonFunc.openNotification("success", "이미지가 삭제되었습니다.", "");
  };

  handlePreviewImage = (file) => {
    Modal.info({
      icon:null,
      content: (
        <div style={{ textAlign: "center" }}>
          <img 
            alt={file.name}
            src={config.serverUrl +
              "/api/getUploadedFile/" +
              this.state.resultIdx +
              "/admin/" +
              file.name + "?" + this.state.imageCacheRenderer +"&isResult=1"}
            style={{ maxWidth: "100%" }}
          />
        </div>
      ),
      width: 800,
      okText: "닫기"
    });
  };

  beforeUpload = (file) => {
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
      CommonFunc.openNotification("error", "이미지 파일만 업로드 가능합니다!", "");
      return false;
    }
    return true;
  };

  uploadFile = async (file, fileName, base64Data) => {

    var blob = this.dataURItoBlob(base64Data,  file.type);
    const convertFile = new File([blob], fileName, {
      type: file.type,
      lastModified: new Date()
    });

    const formData = new FormData();
    formData.append("file", convertFile);
    formData.append("result_idx", this.state.resultIdx); // resultIdx 정보 추가
    formData.append("user_id", "admin"); // issueIdx 정보 추가
    formData.append("encodedFileName", encodeURIComponent(fileName)); // 인코딩된 파일 이름 정보 추가

    await axios
    .post( config.serverUrl + "/api/fileUpload", formData, {
      onUploadProgress: (progressEvent) => {
        // const percent = Math.round(
        //   (progressEvent.loaded * 100) / progressEvent.total
        // );
        // setTimeout(() => {
        //   if (progressStatus === 0) setProgressStatus(1);
        // }, 400); // 1000밀리초 = 1초

        // setProgressRate(percent);
        // if (percent === 100) {
        //   setTimeout(() => {
        //     setProgressStatus(0);
        //   }, 400); // 1000밀리초 = 1초
        // }

        // 프로그레스 바 로그 출력
        //console.log(`업로드 진행률: ${percent}%`);
      },
    })
    .then((response) => {
        // fileList = fileList.map(f => {
          
        //   if (f.uid === file.uid) {
        //     console.log("?", file)
        //     // file.status = "done";
        //     // f.status = "done";
        //   }
        //   return f;
        // })

        // this.setState({
        //   fileList
        // })

        CommonFunc.openNotification(
          "success",
          "파일을 업로드했습니다.",
          ""
        );
    })
    .catch((e) => {
      // 업로드 중에 발생한 오류 처리
      CommonFunc.openNotification(
        "error",
        "파일 저장이 실패했습니다.",
        e.message
      );
    });
  }

  handleChange = async ({ file }) => {
    let convertFileList = this.state.fileList;
    
    try {
      const base64Data = await this.convertFileToBase64(file.originFileObj);
      await this.uploadFile(file, file.name, base64Data);
      if ( this.state.fileList.length === 0) file.isDefault = true;
      convertFileList.push(file);
      this.setState({
        fileList: convertFileList
      })
      // this.imageBase64Cache.set(file.uid, base64Data);
    } catch (error) {
      console.error("Base64 변환 실패:", error);
      CommonFunc.openNotification("error", "이미지 처리 중 오류가 발생했습니다.", "");
    }
  };

  handleEditorChange = (e) => {
    const cursorPosition = e.target.selectionStart;
    this.setState({
      resultContents: e.target.value,
      cursorPosition
    });
  };

  handleEditorClick = (e) => {
    this.setState({
      cursorPosition: e.target.selectionStart
    });
  };

  handleInsertImage = (file) => {
    const { resultContents, cursorPosition } = this.state;
    const imageMarkup = `!${file.name}!`;
    
    const newContent = 
    resultContents.slice(0, cursorPosition) +
      imageMarkup +
      resultContents.slice(cursorPosition);

    this.setState({
      resultContents: newContent,
      cursorPosition: cursorPosition + imageMarkup.length
    }, () => {
      const textArea = this.editorRef.current.resizableTextArea.textArea;
      if (textArea) {
        textArea.focus();
        const newPosition = cursorPosition + imageMarkup.length;
        textArea.setSelectionRange(newPosition, newPosition);
      }
    });

    CommonFunc.openNotification("success", "이미지가 본문에 삽입되었습니다.", "");
  };

  changeDefaultImg = (uid) => {
    let tempFileList = [...this.state.fileList];

    tempFileList = tempFileList.map(file => {
      if (file.uid === uid) {
        file.isDefault = true;
      } else {
        file.isDefault = false;
      }
      return file;
    });
    
    this.setState({
      fileList: tempFileList
    });
  }

  handlePaste = async (event) => {
    const items = (event.clipboardData || event.originalEvent.clipboardData).items;
    
    for (let item of items) {
      if (item.type.indexOf('image') === 0) {
        event.preventDefault();
        const file = item.getAsFile();
        
        if (this.beforeUpload(file)) {
          try {
            const base64Data = await this.convertFileToBase64(file);
            const uid = `paste_${Date.now()}`;
            this.imageBase64Cache.set(uid, base64Data);

            const fileName = `clipboard_image_${Date.now()}.png`;
            this.uploadFile(file, fileName, base64Data);

            const newFile = {
              uid,
              name: fileName,
              status: 'done',
              originFileObj: file,
              isDefault: this.state.fileList.length === 0 ? true : false
            };
            
            this.setState(prevState => ({
              fileList: [...prevState.fileList, newFile]
            }));
            
            CommonFunc.openNotification("success", "이미지가 클립보드에서 추가되었습니다.", "");
          } catch (error) {
            console.error('Base64 변환 실패:', error);
            CommonFunc.openNotification("error", "이미지 처리 중 오류가 발생했습니다.", "");
          }
        }
      }
    }
  };

  convertContentToPreview = (content) => {
    const { fileList, resultIdx } = this.state;
    let previewContent = content;
    
    // 이미지 마크업을 실제 이미지로 변환
    fileList.forEach(file => {
      const imageMarkup = `!${file.name}!`;
      const imgPath = config.serverUrl + "/api/getUploadedFile/" + resultIdx + "/admin/" + file.name + "?" + this.state.imageCacheRenderer + "&isResult=1"
      // const base64Data = this.imageBase64Cache.get(file.uid);
      if (previewContent.indexOf(imageMarkup) !== -1) {
        const imageHtml = `<img src="${imgPath}" alt="${file.name}" style="max-width: 100%; margin: 10px 0;" />`;
        previewContent = previewContent.split(imageMarkup).join(imageHtml);
      }
    });

    // 줄바꿈을 <br>로 변환
    // previewContent = previewContent.replace(/\n/g, '<br>');
    return previewContent;
  }

  handleSave = async () => {
    const { resultIdx, resultTitle, resultContents, fileList, resultIssueStateIdx, resultTags, isShowResult, currentState } = this.state;

    // if (!resultTitle.trim()) {
    //   CommonFunc.openNotification("error", "제목을 입력해주세요.", "");
    //   return;
    // }
    // if (!resultContents.trim()) {
    //   CommonFunc.openNotification("error", "내용을 입력해주세요.", "");
    //   return;
    // }
    if (currentState === "update") {
      if ( resultIssueStateIdx === 0) {
        CommonFunc.openNotification("error", "일감 상태를 선택해주세요.", "");
        return;
      }

      this.saveBtnRef.current.disabled = true;
    }
    
    // 저장할 문서 데이터 구성 (base64 이미지 데이터 포함)
    const reqData = {
      result_contents: resultContents,
      result_images: fileList.map(file => ({
        name: file.name,
        uid: file.uid,
        isDefault: file.isDefault
      })),
      result_tags: resultTags,
      result_issue_state_idx: resultIssueStateIdx,
    }

    if (!resultTitle.trim()) {
      reqData.result_title = "새로운 게시글";
    } else {
      reqData.result_title = resultTitle;
    }

    if (currentState === "insert") {
      reqData.result_idx = resultIdx;
      reqData.is_show_result = false;
      reqData.result_title = "새로운 게시글";
    }
    if (currentState === "update") {
      reqData.result_idx = resultIdx;
      reqData.is_show_result = isShowResult;
    }

    FoodingService.modifyResult(reqData, this.state.currentState)
    .then((res) => {
      console.log(res.data)
      if (res.data) {
        if (currentState === "insert") {
          this.setState({
            isShowResult: false,
            currentState: "update",
            resultIdx: res.data[0].result_idx
          }, () => {
            if (localStorage.getItem("blog-draft")) {
              if (window.confirm("임시저장된 데이터가 있습니다.\n불러올까요?")) {
                this.loadDraft();
              } else {
                this.loadBtnRef.current.disabled = true;
              }
              localStorage.removeItem("blog-draft");

            } else {
              this.loadBtnRef.current.disabled = true;
            }
            
          });


        } else {    
          this.props.history.replace(`/fd/result/detail/${this.state.resultIdx}`);
          CommonFunc.openNotification("success",  `저장을 완료했습니다.`, "");
          localStorage.removeItem("blog-draft");
        }
        
      }
    })
    .catch((err) => {
      CommonFunc.openNotification("error", `저장을 실패했습니다.`, err?.response?.data);
    });
  }
  
  loadDraft = async() => {
    try {
      const savedData = localStorage.getItem("blog-draft");
      if (savedData) {
        const documentData = JSON.parse(savedData);

        const formData = new FormData();
        formData.append("old_result_idx", documentData.resultIdx); // resultIdx 정보 추가
        formData.append("new_result_idx", this.state.resultIdx); // resultIdx 정보 추가
        formData.append("user_id", "admin");

        await axios.post(config.serverUrl + "/api/fileMove", formData)
        .then((res) => {
          if (res.status === 200) {
            CommonFunc.openNotification("success", "파일을 업로드 했습니다..", "");
            // this.props.history.replace(`/fd/list/admin/result`);
          }
        })
        .catch((err) => {
          console.dir(err);
        });

        // 저장된 base64 이미지 데이터를 캐시에 복원
        // documentData.images.forEach(img => {
        //   this.imageBase64Cache.set(img.uid, img.base64);
        // });

        this.setState({
          title: documentData.title,
          resultContents: documentData.content,
          fileList: documentData.images.map(img => ({
            uid: img.uid,
            name: img.name,
            status: 'done',
            isDefault: img.isDefault 
          }))
        });
        CommonFunc.openNotification("success", "임시 저장된 문서를 불러왔습니다.", "");
      }
    } catch (err) {
      console.error('불러오기 실패:', err);
      CommonFunc.openNotification("error", "문서 불러오기에 실패했습니다..", err?.response?.data);
    }
  };

  togglePreview = () => {
    this.setState(prevState => ({
      previewVisible: !prevState.previewVisible
    }));
  };

  customRequest = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  handleClose = removedTag => {
    const resultTags = this.state.resultTags.filter(tag => tag !== removedTag);
    this.setState({ resultTags });
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => {
      this.inputRef.current?.focus();
    });
  };

  handleInputChange = e => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    const { inputValue, resultTags } = this.state;
    if (inputValue && resultTags.indexOf(inputValue) === -1) {
      this.setState({
        resultTags: [...resultTags, inputValue],
        inputVisible: false,
        inputValue: '',
      });
    } else {
      this.setState({
        inputVisible: false,
        inputValue: '',
      });
    }
  }

  deleteResult = async () => {
    FoodingService.deleteResult(this.state.resultIdx)
      .then((res) => {
        if (res.status === 200) {
          CommonFunc.openNotification("success", "삭제가 완료됐습니다.", "");
          this.props.history.replace(`/fd/list/admin/result`);
        } else {
          // Error
          CommonFunc.openNotification("error", "삭제를 실패했습니다..", res.status);
        }
      })
      .catch((err) => {
        console.dir(err);
        CommonFunc.openNotification("error", "삭제를 실패했습니다.", err?.response?.data);
      });
  }


  deletePreviewImage = async (fileName) => {
    // let jsonData = {
    //   idea_idx: props.ideaIdx,
    //   user_id: props.userId,
    //   fileName: fileName,
    // };

    // FoodingService.fileDelete(JSON.stringify(jsonData))
    //   .then((res) => {
    //     if (res.status === 200) {
    //       CommonFunc.openNotification("success", "삭제가 완료됐습니다.", "");
    //       // 업로드 완료 후 데이터 저장 처리 (props로 전달받은 부모 함수 onDeleteFile 호출)
    //       props.onDeleteCompleted(fileName, props.uploadedFiles);
    //     }
    //   })
    //   .catch((err) => {
    //     CommonFunc.openNotification("error", "삭제를 실패했습니다.", err?.response?.data);
        
    //   });
  };

  showUploadFileViewer = (fileName) => {
    this.setState({
      isUplodFileViewerVisible: true,
      viewFileName: fileName,
      viewerModalRerender: this.state.viewerModalRerender + 1
    });
  };

  // UploadFileViewer에서 파일 에디터 모드로 변경시 호출될 펑션
  openUploadFileEditor = async (fileName) => {
    this.setState({
      isUplodFileEditorVisible: true,
      viewFileName: fileName.fileName,
      editorModalRerender: this.state.editorModalRerender + 1
    });
  };

  // UploadFileEditor 모달 창 닫기시 호출될 펑션
  closedUploadFileEditor = async (editUploadedFiles, isUpdated) => {
    // props.onEditCompleted(editUploadedFiles);
    console.log("isUpload", isUpdated)
    if (isUpdated) {
      this.setState({
        imageCacheRenderer: this.state.imageCacheRenderer + 1
      })
    }
    this.setState({
      isUplodFileEditorVisible: false
    })

    // 신규이미지가 추가된 경우 viewer 창 다시 렌더링
    // if (props.uploadedFiles.length !== editUploadedFiles.length)
    //   setViewerModalRerender(viewerModalRerender + 1);
  };




  render() {
    const issueStateList = [...config.issueStateList];
    const { resultIdx, fileList, resultContents, previewVisible, resultTitle, resultTags, inputVisible, inputValue, isShowResult } = this.state;

    const uploadProps = {
      name: 'file',
      multiple: true,
      accept: "image/*",
      listType: 'picture',
      fileList,
      beforeUpload: this.beforeUpload,
      onChange: this.handleChange,
      customRequest: this.customRequest,
      showUploadList: false,
    };

    return (
      <>
      <Card style={{ width: '100%', maxWidth: 1200, margin: '0 auto' }}>
        <Row gutter={[16, 16]}>
         <Col span={24}>
            <div style={{ marginBottom: 16 }}>
                <Button 
                icon={<SaveOutlined />} 
                type="primary"
                ref={this.saveBtnRef}
                onClick={this.handleSave}
                style={{ marginRight: 8 }}
              >
                저장
              </Button>
              <Button 
                icon={<EyeOutlined />}
                style={{ marginRight: 8 }}
                onClick={this.togglePreview}
              >
                미리보기
              </Button>
              <Button
                onClick={this.saveCurrentDocument}
                style={{ marginRight: 8 }}
              >
                임시 저장
              </Button>
              <Button
                ref={this.loadBtnRef}
                onClick={this.loadDraft}
                style={{ marginRight: 8 }}
              >
                불러오기
              </Button>
              <Button
                icon={<DeleteOutlined />}
                type="danger"
                onClick={this.deleteResult}
              >
                삭제
              </Button>
            </div>
          </Col>
          <Col span={24}>
            <Input
              placeholder="제목을 입력하세요"
              value={resultTitle}
              onChange={(e) => this.setState({ resultTitle: e.target.value })}
              size="large"
            />
          </Col>
          <Col span={24}>
              <Row justify="center" align="middle">
                  <Col span={12}>
                    <Space size={"middle"}>
                      <span style={{ color:"rgba(0, 0, 0, 0.85)", fontWeight: 500, marginLeft: "12px" }}>일감 상태* </span>
                      {this.state.writerUserId === this.props.userId || this.props.isAdminAuth ? (
                      <div
                        onClick={(e) => this.showIdeaDropdown(e, issueStateList, 0, "issue_state_idx", this.state.resultIssueStateIdx)}
                        style={{ cursor: "pointer" }}
                      >
                        <div>
                          {this.state.resultIssueStateIdx === 0 ? "[선택]" : issueStateList[this.state.resultIssueStateIdx - 1]} <DownOutlined style={{ fontSize: "9px" }} />
                        </div>
                      </div>
                    ) : (
                      <div>{this.state.resultIssueStateIdx === 0 ? "" : issueStateList[this.state.typeIdx - 1]}</div>
                    )}
                    </Space>
                  </Col>
                  
                  <Col span={5}><span style={{ color:"rgba(0, 0, 0, 0.85)", fontWeight: 500, marginLeft: "12px" }}>게시글 공개 설정*</span></Col>
                  <Col span={7}>
                    <Radio.Group onChange={(e) => {
                        this.setState({
                          isShowResult: e.target.value,
                        });
                      }} value={isShowResult}>
                      <Radio value={true}>전체 공개</Radio>
                      <Radio value={false}>비공개</Radio>
                    </Radio.Group>
                  </Col>
              </Row>
          </Col>
          <Col span={24}>
            <TextArea
              ref={this.editorRef}
              value={resultContents}
              onChange={this.handleEditorChange}
              onClick={this.handleEditorClick}
              placeholder="내용을 입력하세요..."
              style={{ height: 500, marginBottom: 16 }}
            />
          </Col>
 
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Card title="이미지 삽입" bordered={false}>
                  <Upload.Dragger {...uploadProps} style={{ marginBottom: '16px' }}>
                    <div style={{
                      padding: '20px',
                      border: '2px dashed #e9e9e9',
                      borderRadius: '4px',
                      background: '#fafafa',
                      textAlign: 'center',
                      width:'100%'
                    }}>
                      <p className="ant-upload-drag-icon">
                        <PictureOutlined style={{ fontSize: '24px', color: '#40a9ff' }} />
                      </p>
                      <p className="ant-upload-text">이미지를 드래그하거나 클릭하여 업로드하세요</p>
                      {/* <p className="ant-upload-hint" style={{ color: '#888' }}>
                        2MB 이하의 이미지 파일만 가능합니다.
                      </p> */}
                    </div>
                  </Upload.Dragger>
                  <div style={{ 
                    display: "grid", 
                    gridTemplateColumns: "repeat(4, 1fr)", 
                    gridAutoRows: 150,
                    gap: "8px",
                    marginTop: "16px"
                  }}>
                    {fileList.map(file => (
                      <div key={file.uid} className="custom-upload-item" style={{ 
                        position: "relative",
                        width: "100%",
                        height: "100%"
                      }}>
                        {/* 이미지 컨테이너 */}
                        <div style={{ 
                          width: "100%",
                          height: "calc(100% - 32px)",
                          overflow: "hidden",
                          position: "relative",
                          border: file.isDefault ? "red solid 2px" : ""
                        }}>
                          {file.isDefault && <StarTwoTone style={{ marginLeft: "4px"}}/> }
                          <img 
                            src={config.serverUrl +
                              "/api/getUploadedFile/" +
                              resultIdx +
                              "/admin/" +
                              file.name + `?time=${new Date().getTime()}&isThumbnail=true&isResult=1`
                            }
                            alt={file.name}
                            style={{ width: "100%", height: "100%", objectFit: "cover" }}
                          />
                          {/* 호버 시 나타나는 아이콘 버튼들 */}
                          <div className="image-hover-overlay">
                            <div className="hover-buttons">
                              {!file.isDefault && 
                                <Button
                                  type="text"
                                  size="small"
                                  className="hover-icon-button preview-button"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.changeDefaultImg(file.uid);
                                  }}
                                  icon={<StarOutlined />}
                                />
                              }
                              <Button
                                type="text"
                                size="small"
                                className="hover-icon-button preview-button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  // this.handlePreviewImage(file);
                                  this.showUploadFileViewer(file.name)
                                }}
                                icon={<ZoomInOutlined />}
                              />
                              <Button
                                type="text"
                                size="small"
                                className="hover-icon-button delete-button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.handleDeleteImage(file);
                                }}
                                icon={<DeleteOutlined />}
                              />
                            </div>
                          </div>
                        </div>
                        <div style={{
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          right: 0,
                          padding: "4px",
                          background: "#fff",
                          borderTop: "1px solid #f0f0f0",
                          height: "32px"
                        }}>
                          <Button
                            type="primary"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              this.handleInsertImage(file);
                            }}
                            style={{
                              width: "100%",
                              pointerEvents: "auto"
                            }}
                          >
                            본문삽입
                          </Button>
                        </div>
                      </div>
                    ))}
                  </div>
                </Card>
              </Col>
              <Col span={12}>
                <Card title="태그 입력" bordered={false} style={{ height: "100%" }}>
                  <div style={{ minHeight: "400px" }}>
                    {resultTags.length > 0 && 
                      <div style={{ marginBottom: "16px" }}>
                        {resultTags.map((tag, index) => (
                          <Tag
                            key={tag}
                            closable
                            onClose={() => this.handleClose(tag)}
                            style={{ marginBottom: 8, fontSize: "14px", padding: "4px 8px" }}
                          >
                            {tag}
                          </Tag>
                        ))}
                      </div>
                    }
                    {inputVisible ? (
                      <Input
                        ref={this.inputRef}
                        type="text"
                        size="middle"
                        style={{ width: 120 }}
                        value={inputValue}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputConfirm}
                        onPressEnter={this.handleInputConfirm}
                        placeholder="새 태그"
                      />
                    ) : (
                      <Tag 
                        onClick={this.showInput} 
                        style={{ 
                          background: "#fff", 
                          borderStyle: "dashed",
                          cursor: "pointer",
                          padding: "4px 8px"
                        }}
                      >
                        <PlusOutlined /> 태그 추가
                      </Tag>
                    )}
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Modal
          title={resultTitle || "미리보기"}
          visible={previewVisible}
          onCancel={this.togglePreview}
          width={800}
          footer={null}
        >
          {/* <div 
            dangerouslySetInnerHTML={{ 
              __html: this.convertContentToPreview(resultContents) 
            }}
            style={{ 
              padding: '20px',
              minHeight: '300px',
              backgroundColor: '#fff',
              border: '1px solid #f0f0f0',
              borderRadius: '4px'
            }}
          /> */}

          <MarkdownPreview
            style={{ padding: 8 }}
            source={this.convertContentToPreview(resultContents) }
          />
        </Modal>
      </Card>
      <IdeaDropdown
        key={"ResultDropdown" + this.state.ideaDropdownRerender}
        idea_idx={this.props.ideaDetailData?.idea_idx}
        ideaDropdownOptions={this.state.ideaDropdownOptions}
        ideaDropdownOptionType={this.state.ideaDropdownOptionType}
        ideaDropdownValue={this.state.ideaDropdownValue}
        ideaDropdownDisplay={this.state.ideaDropdownDisplay}
        ideaDropdownTop={this.state.ideaDropdownTop}
        ideaDropdownLeft={this.state.ideaDropdownLeft}
        updateIdeaData={this.updateIdeaDetail}
        updateIdeaDetailIdx={this.updateIdeaDetailIdx}
      />
      
      <UploadFileViewer
        key={`viewer${this.state.viewerModalRerender}`}
        rerenderKey={this.state.imageCacheRenderer}
        userId={"admin"}
        writerUserId={"admin"}
        resultIdx={resultIdx}
        isAdmin={true}
        fileName={this.state.viewFileName}
        uploadedFiles={this.state.fileList.map(file => file.name)}
        isUplodFileViewerVisible={this.state.isUplodFileViewerVisible}
        openUploadFileEditor={this.openUploadFileEditor}
      />
      <UploadFileEditor
        key={`editor${this.state.editorModalRerender}`}
        resultIdx={resultIdx}
        writerUserId={"admin"}
        fileName={this.state.viewFileName}
        uploadedFiles={this.state.fileList.map(file => file.name)}
        isUplodFileEditorVisible={this.state.isUplodFileEditorVisible}
        closedUploadFileEditor={this.closedUploadFileEditor}
        showUploadFileViewer={this.showUploadFileViewer}
      />
    </>
    );
  }
}
