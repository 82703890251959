
import React from 'react';
import { useLocation, Link } from 'react-router-dom';

const VerificationFailed = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const message = queryParams.get('message') || '인증에 실패했습니다.';

  return (
    <div className="auth-container">
      <div className="auth-card error-card">
        <h1>인증 실패</h1>
        <p>{message}</p>
        <Link to="/" className="button">로그인 페이지로 이동하기</Link>
      </div>
    </div>
  );
};

export default VerificationFailed;
