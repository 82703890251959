
import React, { useEffect, useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { Button } from 'antd';
import VerificationFailed from './VerificationFailed';

const VerificationSuccess = () => {
  const { login } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);

  
  useEffect(() => {
    authCheck();
   }, []);
 
   const authCheck = async() => {
    if (token) {
      var isSuccess = await login(token);

      console.log("TEST", isSuccess);
      setIsLoginSuccess(isSuccess);
    } else {
      history.replace('/');
    }
  }
  
  const doLogin = () => {
    history.replace('/');
  }

  if (!isLoginSuccess) return <VerificationFailed/>;
  if (isLoginSuccess) {
    return (
      <div className="auth-container">
        <div className="auth-card success-card">
          <h1>인증 성공!</h1>
          <p>이메일 인증이 성공적으로 완료됐습니다.</p>
          <Button onClick={doLogin} >로그인</Button>
        </div>
      </div>
    );
  };
};

export default VerificationSuccess;
