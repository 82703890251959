import React, { Component } from "react";
import moment from "moment";
import "moment/locale/ko";
import { Table, Tooltip, Row, Col, Empty } from "antd";
import "antd/dist/antd.compact.css";

const config = require("../config/config.js");
export default class FoodingMyList extends Component {
  handleFoodingListRow = (record, rowIdx) => {
    return {
      onClick: (e) => {
        window.location.href = `/fd/list/${this.props.userId}/${this.props.spaceIdx}/${record.idea_idx}`;
      },
    };
  }

  render() {
    const feedbackOptions = [...config.feedbackList];

    let columns = [
      {
        title: "등록 일시",
        dataIndex: "create_date",
        key: "create_date",
        width: 30,
        render: (create_date, data) => (
          <div>
            <Tooltip
              title={
                <div>
                  {moment(data.create_date).format("YYYY-MM-DD HH:mm:ss")}
                </div>
              }
              color="#7d8282"
              align="center"
              placement="top"
            >
              <span>{moment(data.create_date).fromNow()}</span>
            </Tooltip>
          </div>
        ),
      },
      {
        title: "스페이스 이름",
        dataIndex: "space_title",
        key: "space_title",
        width: 60,
        ellipsis: true,
      },
      {
        title: "제안 의견",
        dataIndex: "idea_contents",
        key: "idea_contents",
        width: 80,
        ellipsis: true,
        render: (idx, data) => (
          <>
            <Tooltip title={data.idea_contents} color="#7d8282" placement="topLeft">
              {data.idea_contents}
            </Tooltip>
          </>
        ),
      },
      {
        title: "검토 현황",
        dataIndex: "feedback_idx",
        key: "feedback_idx",
        width: 40,
        render: (feedback_idx, data) => <div className="tdText" style={{ color: feedbackOptions[feedback_idx] === "미확인" ? "red" : (feedbackOptions[feedback_idx] === "미채택" ? "grey" : "black") }}>{feedbackOptions[feedback_idx]}</div>,
      },
    ];

    return (
      <div style={{ height: "470px", backgroundColor: "#fff", marginTop: "10px" }}>
        <Row  gutter={[10, 10]}>
          <Col span={24}>
            <Table
              key="defaultTable"
              columns={columns}
              pagination={false} 
              onRow={this.handleFoodingListRow}
              dataSource={this.props.ideaList}
              rowKey="idea_idx"
              locale={{ emptyText: (<Empty description="작성한 의견이 없습니다."/>)}}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
