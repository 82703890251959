import React, { Component} from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "antd/dist/antd.compact.css";
import "./css/default.css";
import Fooding from "./pages/Fooding";
import FoodingLogin from "./pages/FoodingLogin";
import { AuthProvider } from './context/AuthContext';
import VerificationSuccess from './pages/VerificationSuccess';
import VerificationFailed from './pages/VerificationFailed';
import PrivateRoute from './pages/components/PrivateRoute';

class App extends Component {

  render() {
    return (
	    <AuthProvider>
        <Router>
          <Switch>
            <Route exact path="/"                                   component={FoodingLogin} />
            <Route exact path="/login"                              component={FoodingLogin} />
            <Route exact path="/verification-success"               render={() => { return <VerificationSuccess />} } />
            <Route exact path="/verification-failed"                render={() => { return <VerificationFailed />} }/>
            <Route exact path="/fd/select/:userId/"                 render={() => <PrivateRoute><Fooding/></PrivateRoute>} />
            <Route exact path="/fd/list/:userId/"                   render={() => <PrivateRoute><Fooding/></PrivateRoute>} />
            <Route exact path="/fd/list/:userId/result"             render={() => <PrivateRoute><Fooding/></PrivateRoute>} />
            <Route exact path="/fd/edit/result/:resultIdx"          render={() => <PrivateRoute><Fooding/></PrivateRoute>} />
            <Route exact path="/fd/edit/result/"                    render={() => <PrivateRoute><Fooding/></PrivateRoute>} />
            <Route exact path="/fd/result/detail/:resultIdx"        render={() => <PrivateRoute><Fooding/></PrivateRoute>}/>
            <Route exact path="/fd/list/:userId/:spaceIdx"          render={() => <PrivateRoute><Fooding/></PrivateRoute>} />
            <Route exact path="/fd/list/:userId/:spaceIdx/:ideaIdx" render={() => <PrivateRoute><Fooding/></PrivateRoute>} />
            <Route path="/fd/admin"                                 render={() => <PrivateRoute><Fooding/></PrivateRoute>} />
          </Switch>
        </Router>
	    </AuthProvider>
    );
  }
}
export default App;
