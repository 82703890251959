import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card, Row, Col, Spin } from "antd";
import "antd/dist/antd.compact.css";
import { FormOutlined, StarOutlined, FundViewOutlined } from '@ant-design/icons';
import ResultList from "./components/ResultList";
import FoodingService from "../service/FoodingService";
import * as CommonFunc from "./components/functions/CommonFunc.js";
import FoodingSelectSpace from "./FoodingSelectSpace";
import FoodingMyList from "./FoodingMyList";

export default class FoodingHome extends Component {

  state = {
    userId: this.props.userId,
    editableSpaceList: [],
    ideaList: [],
    resultList: [],
    loading: false
  };

  componentDidMount() {
    this.findResultList();
  }

  updateEditableSpaceList = (editableSpaceList) => {
    this.setState({
      editableSpaceList,
      loading: true
    });

    this.findIdeaList({space_idx: editableSpaceList});
  }

  findIdeaList = async (filterParams) => {
    let listFilterParams = {
      ...filterParams,
      page_current: 1,
      page_size: 10,
      user_id: this.state.userId,
    };

    FoodingService.getIdeaList(JSON.stringify(listFilterParams))
    .then((res) => {
      if (res.status === 200) {
        setTimeout(() => {
          this.setState({
            ideaList: res.data,
            loading: false
          });
        }, 1000);
      } else CommonFunc.openNotification("error", "조회를 실패했습니다.", res.status);
    })
    .catch((err) => {
      CommonFunc.openNotification("error", "조회를 실패했습니다.", err?.response?.data);
    });
  }

  findResultList = async () => {
    let listFilterParams = {
      user_id: this.state.userId,
      page_current: 1,
      page_size: 5,
    };

    FoodingService.getResultList(JSON.stringify(listFilterParams))
    .then((res) => {
      if (res.status === 200) {
        let responseData = res.data;
        responseData = responseData.map(response => {
          var contents = response.result_contents;
          var images = JSON.parse(response.result_images);
          // images.map(image => contents = contents.replaceAll("#" + image.name + "#" , ""));
          return {...response, result_images: images, result_contents: contents}; 
        });
        this.setState({
          resultList: responseData
        });
      } else CommonFunc.openNotification("error", "조회를 실패했습니다.", res.status);
    })
    .catch((err) => {
      CommonFunc.openNotification("error", "조회를 실패했습니다.", err?.response?.data);
    });
  }
  
  clickResultRow = (resultIdx) => {
    window.open(`/fd/result/detail/${resultIdx}`, "_blank");
  }

  render() {
    const { userId, ideaList, editableSpaceList, resultList, loading } = this.state;

    if (this.props.isAdminAuth) this.props.history.replace("/fd/admin");

    return (
      <Row justify="center">
        <Col span={resultList.length > 0 ? 12 : 15} >
            <Card style={{ margin: "4px", height:"280px", overflow: "auto"}}>
              <StarOutlined />
              <span style={{ marginLeft: "4px", fontSize: "14px" }}>스페이스 목록</span>
              <FoodingSelectSpace userId={userId} updateEditableSpaceList={this.updateEditableSpaceList} isExistList={ideaList.length > 0} history={this.props.history}/>
            </Card>
            <Card style={{ marginLeft: "4px", marginRight: "4px", marginTop: "8px", height: "560px"}}>
              <Row>
                <Col span={24} style={{ display: "flex", alignItems: "center" }}>
                  <FormOutlined />
                  <div style={{ width: "100%", display: "flex", justifyContent:"space-between", marginLeft: "4px" }}>
                    <span style={{ fontSize: "14px" }}>내가 작성한 의견 (최근 10건)</span>
                    <Link to={`/fd/list/${userId}/${editableSpaceList}`}>{`전체 보기 >`}</Link>
                  </div>
                </Col>
              </Row>
              {loading && (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <Spin />
                </div>
              )}
              {!loading && <FoodingMyList userId={userId} spaceIdx={editableSpaceList} filterSearch={this.filterSearch} ideaList={ideaList}/>}
            </Card>
        </Col>
        {resultList.length > 0 && 
          <Col span={12}>
            <Card style={{ margin: "4px", height: "848px" }}>
              <Row>
                <Col span={24} style={{ display: "flex", alignItems: "center" }}>
                  <FundViewOutlined />
                  <div style={{ width: "100%", display: "flex", justifyContent:"space-between", marginLeft: "4px" }}>
                    <span style={{ marginLeft: "4px", fontSize: "14px"}}> UX Labtory (최근 5건)</span>
                    <a
                      style={{ marginRight: "8px"}} 
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(`/fd/list/${this.state.userId}/result`, "_blank");
                      }}>{`전체 보기 >`}</a>
                  </div>
                </Col>
              </Row>
              <ResultList resultList={resultList} clickResultRow={this.clickResultRow}/>
            </Card>
          </Col>
        }
      </Row>
    )
  }
}
