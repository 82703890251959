import React from "react";
import moment from "moment";
import "moment/locale/ko";
import { Space, List, Typography } from "antd";
import removeMd from 'remove-markdown';
import { EyeOutlined, LikeOutlined, LikeTwoTone, LockOutlined, MessageOutlined } from '@ant-design/icons';
import { MdOutlineFiberNew } from "react-icons/md";
import "antd/dist/antd.compact.css";

const { Paragraph } =Typography;

const config = require("../../config/config.js");

const IconText = ({ icon, text }) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
);
const ResultList = (props) => {
    const issueStateOptions = [...config.issueStateList];
    
    return (
      <>
        <List
          itemLayout="vertical"
          size="large"
          dataSource={props.resultList}
          renderItem={(item) => {
            let resultContents = item.result_contents;
            let filteredThumbnailImageList = item.result_images.filter(image => image.isDefault === true);
            
            if (filteredThumbnailImageList.length === 0) {
              filteredThumbnailImageList = item.result_images.filter(image => {
                return resultContents?.indexOf("!" + image.name + "!") !== -1;
              });
            }
            item.result_images.map(image => resultContents = resultContents.replaceAll("!" + image.name + "!" , ""));
            resultContents = removeMd(resultContents);
            
            return (
            <List.Item
              onMouseEnter={() => {}}
              key={item.result_idx}
              style={{ cursor: "pointer", height: "162px"}}
              onClick={() => props.clickResultRow ? props.clickResultRow(item.result_idx) : null}
            >
              <div style={{ display: "flex" }}>
                <div style={{ 
                    width: "20%",
                    height: "140px",
                    overflow: "hidden"
                  }}>  
                    {filteredThumbnailImageList.length > 0 &&
                      <img
                        alt={filteredThumbnailImageList[0].name}
                        src={config.serverUrl +"/api/getUploadedFile/" + item.result_idx + "/admin/" + filteredThumbnailImageList[0].name + "?isThumbnail=true&isResult=1"}
                        style={{ width: "100%", height: "100%", objectFit: "contain" }}
                      />
                    }
                    {filteredThumbnailImageList.length  === 0 &&
                      <img src={`${process.env.PUBLIC_URL}/noimage.png`} style={{ width: "80%", height: "70%"}}/>
                    }
                </div>
                <div style={{ display: "flex", width: "75%", height: "160px", flexDirection: "column", marginLeft: "4px" }}>
                  <div style={{ height: "20%", display: "flex", alignItems: "center" }}>
                    {!item.is_show_result && <LockOutlined style={{ fontSize: 30, color: "#f00", marginRight:"4px" }}/>}<Paragraph style={{ fontSize: "30px"}} ellipsis={{ rows:1 }}>{item.result_title}</Paragraph>{moment().diff(moment(item.create_date), "weeks") === 0 && <MdOutlineFiberNew style={{ width: "24px", height: "24px", color: "#f00", marginLeft: "4px" }}/>}
                  </div>
                  <div style={{ height: "52%", marginTop: "4px" }}><Paragraph style={{ color: "rgba(0,0,0,0.45)" }} ellipsis={{ rows:4 }}>{resultContents.replace(/\n/gi, "").trim()}</Paragraph></div>
                  <div style={{ width: "100%", height: "10%", display: "flex"}}>
                    <Space style={{ width: "80px" }}>{moment(item.create_date).fromNow()}</Space>
                    <Space style={{ width: "85px" }}>{issueStateOptions[item.result_issue_state_idx - 1]}</Space>
                    <Space style={{ width: "48px" }}><IconText icon={item.like_idx ? LikeTwoTone : LikeOutlined} text={item.like_cnt} key="list-vertical-like-o" /></Space>
                    <Space style={{ width: "48px" }}><IconText icon={MessageOutlined} text={item.comment_cnt} key="list-vertical-message" /></Space>
                    <Space style={{ width: "48px" }}><IconText icon={EyeOutlined} text={item.view_cnt} key="list-vertical-message" /></Space>
                  </div>
                </div>
              </div>
            </List.Item>
          )}}
        />
        <style>
          {`
            .ant-list-item:hover {
              background-color: #efef 
            }
          `}
        </style>
      </>
    );
}

export default ResultList;