import React, { useState, useContext } from 'react';
import { Card, Row, Col, Form, Space, Button, Input, Radio } from "antd";
import * as CommonFunc from "./components/functions/CommonFunc.js";
import FoodingService from "../service/FoodingService";
import CryptoJS from "crypto-js";
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const FoodingLogin = () => {
  const [email, setEmail] = useState('somansa.com');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [messageType, setMessageType] = useState('');
  const [loginId, setLoginId] = useState('');
  const [loginPw, setLoginPw] = useState('');
  const [isAdminLogin, setIsAdminLogin] = useState(false);
  const { isAuthenticated, user, login, isAdminAuth  } = useContext(AuthContext);
  const history = useHistory();
  const [isLogin, setIsLogin] = useState(false);

  // 이미 인증된 사용자는 홈 화면으로 리다이렉트
  if (isAuthenticated) {
    if (isAdminAuth) {
      history.push(`/fd/admin`);
    } else {
      // 사용자 정보가 없으면 루트경로로 보냄
      if (user) {
        history.push(`/fd/select/${user.email}`);
      } else {
        history.push(`/`);
      }
    }
    return null;
  }

  const doLogin = async (e, isReSend = false) => {
    e.preventDefault();
    if (isAdminLogin) {
      loginWithAdmin();
    } else {
      if (loginId && loginId.indexOf("admin") !== -1) {
        CommonFunc.openNotification("error", "관리자로 로그인하려면 관리자 모드로 변경해주십시오.");
        return;
      }
      if (!loginId || loginId.trim() === "") {
        CommonFunc.openNotification("error", "아이디를 입력해주십시오.");
        return;
      }
      if (!validateEmail(loginId)) {
        CommonFunc.openNotification("error", "이메일을 확인해주십시오.");
        return;
      }
      CommonFunc.openNotification("success", "인증 이메일을 전송했습니다.");

      setIsLoading(true);
      setIsLogin(true);
      // setMessageType("success");

      // history.replace({
      //   pathname: "/fd/select/" + loginId, // 스페이스 선텍 페이지로 이동
      // });
      try {
        const res = await FoodingService.loginWithEmail({ email: loginId, isReSend });

        setMessage(res.data.message);
        setMessageType('success');
        setEmail('');
      } catch (error) {
        setMessage(error.response?.data?.message || '오류가 발생했습니다. 다시 시도해주십시오.');
        setMessageType('error');
      } finally {
        setIsLoading(false);
      }
    }
  }

  const getConvertEncryptedPw = () => {
    // 암호화를 위한 랜덤 키값 생성
    let iv = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);
    let salt = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);
    let keySize = 128;
    let iterationCount = 10000;
    let passPhrase = "aaaaqqqqzzzz";

    // PBKDF2 키 생성
    let key128Bits100Iterations = CryptoJS.PBKDF2(passPhrase, CryptoJS.enc.Hex.parse(salt), { keySize: keySize / 32, iterations: iterationCount });
    let encrypted = CryptoJS.AES.encrypt(loginPw, key128Bits100Iterations, { iv: CryptoJS.enc.Hex.parse(iv) });
    let cipherText = encrypted.ciphertext.toString(CryptoJS.enc.Base64);

    return {
      encryptedPw: cipherText,
      iv,
      salt,
    };
  }

  // 관리자 로그인 -> 암호화해서 아이디, 비밀번호, iv, salt 등을 서버에 넘겨준다.
  const loginWithAdmin = () => {
    if (!loginId || loginId.trim() === "") {
      CommonFunc.openNotification("error", "아이디를 입력해주십시오.");
      return;
    }
    if (!loginPw || loginPw.trim() === "") {
      CommonFunc.openNotification("error", "비밀번호를 입력해주십시오.");
      return;
    }

    let { encryptedPw, iv, salt } = getConvertEncryptedPw();
    let jsonData = { admin_id: loginId, admin_password: encryptedPw, iv, salt };

    FoodingService.login(jsonData)
      .then((res) => {
        if (res.status === 200) {
          login(res.data.token, true);

          history.replace({
            pathname: "/fd/admin",
            state: { userId: loginId },
          });

          CommonFunc.openNotification("success", "로그인 성공");
        } else {
          CommonFunc.openNotification("error", "잠시 후 다시 시도해주십시오.");
        }
      })
      .catch((err) => {
        CommonFunc.openNotification("error", err.response?.data);
      });
  }

  const  validateEmail = email => {
    const regex = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
    return regex.test(email);
  }

  return (
    <Row gutter={(10, 10)} align="middle" justify="center">
      <Card
        style={{
          width: isAdminLogin ? 320 : (isLogin && message ? 430 : 340),
          backgroundColor: isLogin ? "#fff" : "#f1f3f7",
          boxShadow: "0 0px 7px 0px rgba(0,0,0,0.035), 0 2px 9px -1px rgba(0,0,0,0.095), 0 4px 8px 1px rgba(0,0,0,0.04)",
          transform: "translate(-50%)",
          position: "absolute",
          top: "30%",
          left: "50%",
        }}
      >
        {!isLogin && <>
          <Col span={21} push={3} style={{ marginBottom: 16 }}>
            <img src={`${process.env.PUBLIC_URL}/Fooding_Logo.svg`} width="210px" alt="Fooding" />
          </Col>
          <Col span={24} align="middle" style={{ marginBottom: 16 }}>
            <Radio.Group
              onChange={(e) => { setIsAdminLogin(e.target.value); }}
              value={isAdminLogin}
            >
              <Space>
                <Radio value={false}>사용자</Radio>
                <Radio value={true}>관리자</Radio>
              </Space>
            </Radio.Group>
          </Col>
          <Col span={24}>
            <Form labelCol={{ span: 4 }} colon={false}>
              <Form.Item label={isAdminLogin ? "아이디" : "이메일"}>
                <Input type="text" style={{ marginRight: 5}} value={loginId} onPressEnter={(e) => { if(!isLogin) {doLogin(e)} } } autoFocus={true} onChange={(e) =>  setLoginId(e.target.value)} />  
                {/* {!this.state.isAdminLogin && 
                <>
                <span>@</span>
                <Input type="text" style={{ width: 120, marginLeft: 5 }} value={this.state.customEmail} onPressEnter={(e) => this.login()}  autoFocus={true} onChange={(e) => this.setState({ customEmail: e.target.value })} />
                </>
                } */}
              </Form.Item>
              {isAdminLogin && (
                <Form.Item label="비밀번호">
                    <Input type="password"  value={loginPw} onPressEnter={(e) => { if(!isLogin) { doLogin(e) } } } autoFocus={true} onChange={(e) => setLoginPw(e.target.value)} />
                </Form.Item>
              )}
            </Form>
          </Col>
          <Col span={24}>
            <Row justify="center">
              <Col>
                <Space size={2}>
                <Button type="primary" disabled={isLogin} onClick={(e) =>{ doLogin(e) }}>
                  로그인
                </Button>
                </Space>
              </Col>
              {/* <Col>
                <Space size={2}>
                  <Button onClick={this.changeLoginMode}>{this.state.isAdminLogin ? "사용자 로그인" : "관리자 로그인"}</Button>
                </Space>
              </Col> */}
            </Row>
          </Col>
        </>}
        
        {(isLogin && message) && (
          <>
            <div className="message">
              <div><h2>이메일 인증</h2></div>
              <div dangerouslySetInnerHTML={{ __html: message}}/>
            </div>
            <Col span={24}>
              <Row justify="center">
                <Col>   
                  <div style={{ display:"flex", flexDirection: "column", alignItems:"center",  whiteSpace: "pre-wrap"  }} >
                    <div>{`이메일을 확인할 수 없나요?`} </div>
                    <div>스팸 편지함 확인 또는 <a onClick={(e) => doLogin(e, true) }>인증 이메일 다시 보내기</a></div>
                  </div>
                </Col>
              </Row>
            </Col>
          </>
         )}
      </Card>
    </Row>
  );
}


export default FoodingLogin;