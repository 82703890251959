import React, { useContext } from "react";
import { Layout } from "antd";
import HeaderContainer from "../layouts/Header";
import FoodingList from "./FoodingList";
import FoodingResultList from "./FoodingResultList";
import FoodingResultEditor from "./FoodingResultEditor";
import FoodingResultDetail from "./FoodingResultDetail";
import FoodingHome from "./FoodingHome";
import { Route, Switch } from "react-router-dom";
import { withRouter, useHistory } from "react-router-dom";
import { AuthContext } from '../context/AuthContext';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

/*
  실제 기능에 대한 화면은 
  FoodingLogin
  Fooding - FoodingList

  이런식으로 2개의 페이지임
  Fooding Component에서는 공통 헤더안에 여러개의 route로 나눠서, 추후에 변경될 화면에 대응.
*/
const Fooding = (props) => {
  /*
   나중에 아이디 정보 가져오는 부분 따로 수정해야함 일단은 임시로 url 경로에서 따다가 변경했음
   현재 아이디 정보는 url에서 추출해서 가져오고 있는상태
  */
  
  const { user, isAdminAuth  } = useContext(AuthContext);
  const history = useHistory();
  // const userId = isAdminAuth  ? "admin" : user.email;
  const userId = isAdminAuth  ? "관리자" : user.email


  return (
    <Layout>
      <Layout.Header>
        <HeaderContainer />
      </Layout.Header>
      <Layout.Content style={{ padding: "8px" }}>
        <Switch>
          <Route exact path="/fd/select/:userId/"                 render={() => { return <FoodingHome{...props} userId={userId} history={history} isAdminAuth={isAdminAuth} />}}  />
          <Route exact path="/fd/list/:userId"                    render={() => { return <FoodingList {...props} isAdminAuth={isAdminAuth} />}} />
          <Route exact path="/fd/list/:userId/result/"            render={() => { return <FoodingResultList {...props} isAdminAuth={isAdminAuth}/>}}/>
          <Route exact path="/fd/edit/result/:resultIdx"          render={() => { return <FoodingResultEditor {...props} isAdminAuth={isAdminAuth} />}} />
          <Route exact path="/fd/edit/result/"                    render={() => { return <FoodingResultEditor {...props} isAdminAuth={isAdminAuth} />}}/>
          <Route exact path="/fd/result/detail/:resultIdx"        render={() => { return <FoodingResultDetail {...props} isAdminAuth={isAdminAuth} />}}/>
          <Route exact path="/fd/list/:userId/:spaceIdx"          render={() => { return <FoodingList {...props} isAdminAuth={isAdminAuth} />}} />
          <Route exact path="/fd/list/:userId/:spaceIdx/:ideaIdx" render={() => { return <FoodingList {...props} isAdminAuth={isAdminAuth} />}} />
          <Route
            path="/fd/admin"
            render={() => {
              if (isAdminAuth) {
                return <FoodingList isAdminAuth={isAdminAuth} history={history} />;
              } else {
                setTimeout(function() {
                  history.replace("/");
                }, 1000);
                return <div>관리자 권한이 없습니다.</div>;
              }
            }}
          />
        </Switch>
      </Layout.Content>
    </Layout>
  );
}

export default withRouter(Fooding);
